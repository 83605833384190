.admin-suggestUsers-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.admin-suggestUsers-container h2 {
  margin-bottom: 10px;
  font-size: var(--xs-font-size);
}

.admin-suggestUsers-container hr {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.suggestUsers-container-table th img.table-icon {
  display: none; 
}
.recommended-name,
.recommended-by {
  width: 130px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.suggestUsers-container-table-container {
  max-height: 700px;
  overflow-y: auto;
}

.suggestUsers-container-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: var(--container-shadow);
  font-size: var(--xxs-font-size)!important;
  table-layout: fixed;
}

.suggestUsers-container-table a {
  text-decoration: none;
  color: black;
  text-shadow: var(--text-shadow);
}

.suggestUsers-container-table th,
.suggestUsers-container-table td {
  padding: 8px;
  border: 1px solid #ddd;
  /* white-space: nowrap; */
  text-align: center;
}

.suggestUsers-container-table th {
  cursor: pointer;
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
}

.suggestUsers-container-table th:hover,
.suggestUsers-container-table td:hover {
  background-color: #f1f1f1;
}

.suggestUsers-container-table .number-cell {
  width: 50px;
}

.suggestUsers-container-table tbody tr {
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-unapproved {
  padding: 6px 10px;
  margin: 2px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-second {
  background-color: var(--green-second);
}

.orange {
  background-color: var(--orange);
}

.red {
  background-color: var(--red);
}

.actions-admin-suggestUsers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center;
  align-items: center;
  padding: 30px;
}

.suggestUsers-container-table-container::-webkit-scrollbar {
  width: 10px;
}

.suggestUsers-container-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.suggestUsers-container-table-container::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid #f1f1f1;
  border-radius: var(--radius-input);
}

.search-container-suggestUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-container-suggestUsers input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
}

.search-container-suggestUsers select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.search-container-suggestUsers button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.search-container-suggestUsers .reset-icon-suggestUsers {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
}

.no-result-fly {
  font-size: var(--m-font-size);
}

.comment-icon-suggestUsers {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.6s ease, filter 0.6s ease, box-shadow 0.3s ease;
}

.comment-icon-suggestUsers:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.comment-icon-suggestUsers:active {
  transform: scale(0.95);
  filter: brightness(0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.clickable-text {
  cursor: pointer;
  color: var(--orange);
  text-decoration: none;
  font-size: var(--xs-font-size)!important;
}
.reset-icon-suggestUsers{
  display: none;
}
@media (max-width: 767px) {
  .admin-suggestUsers-container h2 {
    font-size: var(--s-font-size);
  }

  .suggestUsers-container-table .number-cell {
    width: 100%;
  }

  .id-table-admin {
    background-color: var(--gravel);
  }
  .clickable-text {
    font-size: var(--xs-font-size)!important;
  }
  .suggestUsers-container-table {
    font-size: var(--xs-font-size)!important;
  }

  .suggestUsers-container-table th,
  .suggestUsers-container-table td {
    padding: 6px;
  }

  .suggestUsers-container-table th {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .suggestUsers-container-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .btn-unapproved {
    padding: 6px 8px;
    margin: 2px;
  }

  .actions-admin-suggestUsers {
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }

  .suggestUsers-container-table tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .suggestUsers-container-table tr td.actions-admin {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .all-name-td {
    word-wrap: break-word !important;  /* Позволява пренасяне на думите */
    max-width: 150px;       /* Максимална ширина на клетката */
  }
  .admin-suggestUsers-container h2 {
    font-size: var(--s-font-size);
  }
  .admin-suggestUsers-container {
    width: 90%;
  }
  .message-suggest-all{
    width: 120px;
  }
  .recommended-phone{
    width: 110px;
  }
  .ads-cell {
    width: 55px;
  }

  .registration-date-admin {
    width: 155px;
  }

  .suggestUsers-container-table {
    font-size: var(--xxs-font-size)!important;
  }
  .suggestUsers-container-table  span{
    font-size: var(--xxs-font-size)!important;
  }

  .suggestUsers-container-table th,
  .suggestUsers-container-table td {
    padding: 10px;
  }

  .suggestUsers-container-table th{
    font-size: var(--xxs-font-size)!important;

  }
  .btn-unapproved {
    padding: 8px 12px;
    margin: 5px;
  }

  .actions-admin-suggestUsers {
    gap: 10px;
  }
  .number-cell{
    width: 80px;
  }
  .comment-icon-suggestUsers {
    width: 30px;
    height: 30px;
  }

  .suggestUsers-container-table th span {
      display: none; 
  }

  .suggestUsers-container-table th img.table-icon {
      display: inline-block;
      width: 25px;
  }
  .reset-text-suggestUsers {
    display: none;
  }
  .search-container-suggestUsers .reset-icon-suggestUsers {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (min-width: 980px) and (max-width: 1200px) {
  .admin-suggestUsers-container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .admin-suggestUsers-container h2 {
    font-size: var(--l-font-size);
  }
  .admin-suggestUsers-container{
    width: 100%;
  }
  .clickable-text {
    font-size: var(--xs-font-size)!important;
  }
  .ads-cell {
    width: 100px;
  }
  .recommended-name,
  .recommended-by {
    width: 305px;
  }
  .suggestUsers-container-table {
    font-size: var(--xs-font-size)!important;
  }

  .suggestUsers-container-table th,
  .suggestUsers-container-table td {
    padding: 12px 15px;
    text-align: center;
  }

  .suggestUsers-container-table th {
    font-size: var(--m-font-size);
  }

  .btn-unapproved {
    padding: 10px 15px;
    margin: 10px;
  }

  .actions-admin-suggestUsers {
    gap: 0.4em;
  }

  .comment-icon {
    width: 50px;
    height: 50px;
  }

  .suggestUsers-container-table th span {
      display: inline-block; 
  }

  .suggestUsers-container-table th img.table-icon {
      display: none; 
  }
  .reset-text-suggestUsers {
    display: none;
  }
  .search-container-suggestUsers .reset-icon-suggestUsers {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .search-container-suggestUsers {
    flex-direction: column;
    align-items: center;
  }

  .search-container-suggestUsers input[type="text"],
  .search-container-suggestUsers select,
  .search-container-suggestUsers button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-container-suggestUsers button,
  .search-container-suggestUsers .reset-icon-suggestUsers {
    padding: 10px;
  }

  .search-container-suggestUsers .reset-icon-suggestUsers {
    align-self: flex-end;
    font-size: 1.2rem;
  }
  .reset-text-suggestUsers {
    display: block;
    margin-left: 0;
    background-color: var(--yellow);
    text-align: center;
    border-radius: var(--radius-input);
    padding: 0.5em;
  }
}

@media (max-width: 450px) {
  .comment-icon-suggestUsers {
    width: 40px;
    height: 40px;
  }
}

.legend-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend-item span {
  font-size: var(--xxs-font-size);
  color: var(--orange);
  text-decoration: none;
  align-items: center;
}

.legend-icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 1200px) {
  .legend-container {
    gap: 30px;
  }
  .recommended-phone{
    width: 160px;
  }
  .message-suggest-all{
    width: 200px;
  }
}

@media (min-width: 800px) {
  .legend-container {
    gap: 25px;
  }
}

@media (max-width: 767px) {
  .legend-container {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}

.clickable-text {
  cursor: pointer;
  color: var(--orange);
  text-decoration: none;
}

.text-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.text-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

.text-modal-content .close-button {
  text-align: center;
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 40px;
  cursor: pointer;
  color: black !important;
  opacity: 1;
  background-color: var(--gravel);
  padding: 1px 10px;
  border-radius: 80%;
  text-decoration: none;
  text-indent: initial;
  transition: transform 0.3s ease-in-out;
}

.text-modal-content .close-button:hover {
  transform: rotate(90deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .recommended-name,
  .recommended-by {
    width: 200px;
  }
  .suggestUsers-container-table {
    font-size: var(--xxs-font-size)!important;
  }
  .clickable-text {
    font-size: var(--xxs-font-size)!important;
}
.message-suggest-all{
  width: 130px;
}
}