.search-bar-commun {
    top: 30vh;
    margin: 5em auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.4em 1em;
    width: 100%;
    max-width: 310px;
    border: 2px var(--green-cm-light) solid;
    border-radius: var(--radius-input);
    z-index: 100;

}

.search-bar-commun p {
    display: none;
    color: var(--green-cm);
    font-weight: 800;
    font-size: calc(var(--s-font-size));
}

.divider {
    width: 1px;
    height: 35px;
    background-color: var(--green-cm-light);
    margin: 0 0.5em;
}

.commun-icon {
    color: var(--green-cm-light) !important;
    font-size: calc(var(--s-font-size) - 2px);
    margin-right: 0.5em;
}

.icons-com {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

}

.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
    border: none;
    color: white;
    font-size: var(--xs-font-size);
    padding: 0.5em 1.5em;
    border-radius: var(--radius);
    cursor: pointer;

    margin-left: 1.5em;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .search-bar-commun {
        max-width: 60%;

    }

    .icons-com {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .hero-section-commun p {
        display: block;
        color: var(--green-text);
        font-size: var(--xs-font-size);
        font-weight: 800;
    }
}

@media (min-width: 1200px) {
    /* .main-community {
        max-width: 1280px;
        margin: 0 auto;
        background-color: var(--yellow);
        border-radius: 15px;
    } */

    .community-page {
        padding: 2em 0;
    }

    /* .hero-bg {
        width: 100%;
        height: 400px;
        display: flex;
        background-image: url('../../../public/community/community-bg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
        margin-top: 4em;

        filter: brightness(65%);

    } */

    .search-bar-commun {
        position: absolute;
        width: 1200px;
        max-width: initial;
        background-color: var(--bg-white);
        padding: 1.2em 1.8em;
        gap: 0;
    }

    .commun-icon {
        font-size: var(--m-font-size);

    }

    .search-button {
        font-size: var(--xs-font-size);
        /* padding: 16px 38px; */
        /* margin-left: 5.5em; */

    }

    .icons-com {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .hero-section-commun p {
        display: block;
        color: var(--green-text);
        font-size: var(--xxs-font-size);
        font-weight: 800;
        text-transform: uppercase;
        margin-left: 12px;
    }

    .divider {
        height: 50px;
    }

    .after-header {
        display: none;
    }

    .search-bar-commun p {
        display: block;
    }

}