
.mapeditor-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    z-index: 9999;
}

.mapeditor-modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

.mapeditor-modal-content {
    background-color: rgba(255, 255, 255, 0.8);;

    padding: 20px;
    border-radius: 8px;
    text-align: center;
    text-shadow: var(--text-shadow);
    color: var( --gray);
    font-weight: 200;
    font-size: var(--xs-font-size);
}
