.banner-section-suggest {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ),
    url('../../../public/images/sign-up/selfie-seniors2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: var(--xxxl-space) 0;
}

.container-suggest {
  border-radius: var(--radius);
  box-shadow: var(--container-shadow);
  height: var(--container-larger-height);
  min-height: 940px;
  max-width: var(--container-max-width);
  overflow: hidden;
  position: relative;
  width: var(--container-width);
  opacity: 0.8;
}

.container-suggest .form-suggest {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 var(--xl-space);
  height: 100%;
  text-align: center;
}

.container-suggest .form-suggest span {
  color: var(--red);
}

.container-suggest .form-suggest__title {
  text-transform: uppercase;
  color: var(--orange);
  margin: var(--l-space) var(--m-space) var(--m-space) var(--m-space);
}

.container-suggest .input {
  background-color: var(--bg-white);
  padding: 0.9rem 0.9rem;
  margin: 0.5rem 0 1rem 0;
  width: 100%;
  border: 0.3px solid #ccc;
  border-radius: 10px;
}

.container-suggest .desc {
  font-size: var(--xs-font-size);
  text-align: left;
  text-indent: 0%;
  margin: var(--s-space) 0;
  width: 100%;
}

@media (max-width: 600px) {
  .container-suggest .form-suggest__title {
    font-size: var(--m-font-size);
  }

  .container-suggest {
    width: 100%;

  }
  .banner-section-suggest {
    padding-top: 20px;
  }
}

@media (min-width: 850px) {
  .container-suggest {
    max-width: 1000px;
    min-height: var(--container-min-height);
    background-image: var(--bg-image);
  }

}
.container-suggest .label {
  color: var(--gray);
  letter-spacing: 0.05rem;
  text-align: left;
  align-self: flex-start;
  text-indent: 0.3rem;
}
.error-text {
  color: red;
  margin-top: 5px;
}
