.reject-announcements-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  
  .reject-announcements-container h2 {
    margin-bottom: 10px;
    font-size: var(--xs-font-size);
  }
  
  .reject-announcements-container hr {
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .reject-announcements-table-container {
    max-height: 700px;
    overflow-y: auto;
  }
  
  .reject-announcements-table{
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--container-shadow);
    font-size: var(--xxs-font-size);
    table-layout: fixed;
  }
  
  .reject-announcements-table a {
    text-decoration: none;
    color: black;
    text-shadow: var(--text-shadow);
  }
  
  .reject-announcements-table th,
  .reject-announcements-table td {
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    /* text-align: left; */
    text-align: center;
  }


  .reject-announcements-table th {
    cursor: pointer;
    background-color: #f4f4f4;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .reject-announcements-table th:hover,
  .reject-announcements-table td:hover {
    background-color: #f1f1f1;
  }
  
  .reject-announcements-table .number-cell {
    width: 70px;
  }
  
  .reject-announcements-table tbody tr {
    border-bottom: 1px solid #ccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .btn-unapproved {
    padding: 6px 10px;
    margin: 2px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .green-second {
    background-color: var(--green-second);
  }
  
  .orange {
    background-color: var(--orange);
  }
  
  .red {
    background-color: var(--red);
  }
  
  .actions-admin {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    justify-items: center; 
    align-items: center;
    padding: 30px;
    
  }
  

  .reject-announcements-table-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .reject-announcements-table-container::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .reject-announcements-table-container::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border: 2px solid #f1f1f1;
    border-radius: var(--radius-input);
  }
  .search-container-reject {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.search-container-reject input[type="text"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px;
    margin-right: 10px;
}

.search-container-reject select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

.search-container-reject button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.search-container-reject .reset-icon-reject {
    font-size: 1.5rem;
    color: #007bff;
    cursor: pointer;
}
.no-result-fly{
  font-size: var(--m-font-size);
}
.comment-icon {
  width: 60px;
  height: 60px;
  cursor: pointer;
 
  margin-right: 10px;
  transition: transform 0.6s ease, filter 0.6s ease, box-shadow 0.3s ease;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.comment-icon:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.comment-icon:active {
  transform: scale(0.95);
  filter: brightness(0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.trimmed-tittle-reject a{
  color: var(--orange);
  cursor: pointer;
}
.trimmed-email-reject a{
  color: var(--orange);
  cursor: pointer;
}
.text-modal-overlay-reject {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.text-modal-content-reject-ads {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

.text-modal-content-reject-ads .close-button-reject {
  text-align: center;
  position: absolute;
  top: -80px;
  right: -20px;
  font-size: 40px;
  cursor: pointer;
  color: black !important;
  opacity: 1;
  background-color: var(--gravel);
  padding: 0px 10px;
  border-radius: 50%;
  text-decoration: none;
  text-indent: initial;
  transition: transform 0.3s ease-in-out;
}

.text-modal-content-reject-ads .close-button-reject:hover {
  transform: rotate(90deg);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
.reset-icon-reject{
  display: none;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .reject-announcements-table th img.table-icon {
      display: inline-block;
      width: 25px;

  }
  .reject-announcements-table th span {
      display: none;
  }
  /* .approved-announcements-table th{
    width: 100px;
  } */
  .reset-text-reject {
    display: none;
  }
  .search-container-reject .reset-icon-reject {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (max-width: 767px), (min-width: 1201px) {
  .reject-announcements-table th img.table-icon {
      display: none;
  }
  .reject-announcements-table th span {
      display: inline;
  }
}
  @media (max-width: 767px) {
    .reject-announcements-container h2 {
      font-size: var(--s-font-size);
    }
    .reject-announcements-table .number-cell {
        width: 100%;
    }
  .id-table-admin{
    background-color: var(--gravel);
  }
    .reject-announcements-table {
      font-size: var(--xs-font-size);
    }
  
    .reject-announcements-table th,
    .reject-announcements-table td {
      padding: 6px;
    }
  
    .reject-announcements-table th {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .reject-announcements-table td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    .btn-unapproved {
      padding: 6px 8px;
      margin: 2px;
    }
  
    .actions-admin {
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;
    }
  
    .reject-announcements-table tr {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .reject-announcements-table tr td.actions-admin {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .search-container-reject {
      flex-direction: column;
      align-items: center;
    }
    .reset-text-reject {
      display: block;
      margin-left: 0;
      background-color: var(--yellow);
      text-align: center;
      border-radius: var(--radius-input);
      padding: 0.5em;
    }
    .search-container-reject input[type="text"],
    .search-container-reject select,
    .search-container-reject button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    
    .search-container-reject button,
    .search-container-reject .reset-icon-reject {
      padding: 10px;
    }
        
  }
  @media (min-width: 900px) and (max-width: 1100px) {
    .actions-admin {
  
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 768px) and (max-width: 950px) {
    .reject-announcements-table th {
      font-size: var(--xxs-font-size);
    }
    .reject-announcements-table th,
    .reject-announcements-table td {
      font-size: var(--xxs-font-size);

    }
    .th-title-reject{
      width: 160px;
    }
    .th-date-reject{
      width: 160px;
    }
    .th-email-reject{
      width: 120px;
    }
  }

  @media (min-width: 950px) and (max-width: 1200px) {
    .reject-announcements-container h2 {
      font-size: var(--s-font-size);
    }
   
    .reject-announcements-table {
      font-size: var(--xs-font-size);
    }
    .th-title-reject{
      width: 180px;
    }
    .th-date-reject{
      width: 200px;
    }
    .th-email-reject{
      width: 160px;
    }
    .reject-announcements-table th,
    .reject-announcements-table td {
      padding: 10px;
    }
    .reject-announcements-table th {
      font-size: var(--xs-font-size);
    }
    .btn-unapproved {
      padding: 8px 12px;
      margin: 5px;
    }
  
    .actions-admin {
      gap: 10px;
    }
    .comment-icon {
      width: 40px;
      height: 40px;
    }
  }
  @media (min-width: 980px) and (max-width: 1200px) {
    .reject-announcements-container {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .reject-announcements-container h2 {
      font-size: var(--l-font-size);
    }
    .reject-announcements-container {
      width: 100%;
    }
    .reject-announcements-table {
      font-size: var(--xs-font-size);
    }
  
    .reject-announcements-table th,
    .reject-announcements-table td {
      padding: 12px 15px;
    }
  
    .reject-announcements-table th {
      font-size: var(--xs-font-size);
    }
  
    .btn-unapproved {
      padding: 10px 15px;
      margin: 10px;
    }
  
    .actions-admin {
      gap: 1em;
    }
    .comment-icon {
      width: 60px;
      height: 60px;
    }
    .reset-text-reject {
      display: none;
    }
    .search-container-reject .reset-icon-reject {
      font-size: 1.5rem;
      color: var(--green) !important;
      cursor: pointer;
      display: inline-block;
    }
  }
  
@media (max-width: 767px) {
    .search-container-reject {
        flex-direction: column;
        align-items: stretch;
    }

    .search-container-reject input[type="text"], 
    .search-container-reject select, 
    .search-container-reject button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .search-container-reject button, 
    .search-container-reject .reset-icon-reject {
        padding: 10px;
    }

    .search-container-reject .reset-icon-reject {
        align-self: flex-end;
        font-size: 1.2rem;
    }
}
@media (max-width: 450px) {
  .comment-icon {
    width: 40px;
    height: 40px;
  }
}