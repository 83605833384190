.what-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.what-modal-content {
    background: var(--grey-bg);
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.what-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.what-modal-content h2 {
    margin-top: 0;
font-size: calc(var(--m-font-size) + 5px);

}

.what-input,
.what-select {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: var(--xs-font-size);

}

.what-select {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.open-categories {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
}

.what-search-button {
    padding: 0.5rem 1rem;
    border: none;
    background: var(--yellow);
    color: white;
    border-radius: 5px;
    font-size: var(--xs-font-size);
    cursor: pointer;
    display: block;
    width: 100%;
}


.what-categories {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding-top: 10px;
    padding-left: 8px;
}

.what-categories label {
    display: flex;
    /* flex-direction: row-reverse; */
    margin-right: auto;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: var(--xxs-font-size);
    text-transform: uppercase;
}

.what-categories label input {
    appearance: none;
    width: 24px;
    height: 24px;
    padding: 2px;
    border: 4px solid var(--green);
    /* border-radius: 50%; */
    outline: none;
    cursor: pointer;
    background-color: #fff;
    margin-right: 12px;
}

.what-categories label input:checked {
    background-color: var(--orange);
    border-color: var(--green);
}

.what-modal-content .btn-general, where-modal-content .btn-general , .when-modal-content .btn-general{
    width: 100%;
}
@media (min-width: 768px) and (max-width: 1199px) {
    .what-search-button {
        font-size: var(--s-font-size);
    }
}

@media (min-width: 1200px) {
    .what-search-button {
        font-size: var(--s-font-size);
    }

    .what-input,
    .what-select {
        font-size: var(--xs-font-size);
    }

    .what-select {
        display: none;
    }
}