.gallery-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  gap: 2em;
  width: 90%;
}

.main-image-wrapper {
  position: relative;
}

.main-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: width 0.3s ease;
  z-index: 1;
}

.enlarged-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  width: 100%;
}

.main-image.enlarged {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  width: 80vw;
  height: auto;
  object-fit: cover;
}

.thumbnail-wrapper {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.thumbnail {
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.overlay {
  display: none;
}

.overlay.visible {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 111;
}

.enlarged-wrapper .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  box-shadow: none;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

@media (min-width: 1200px) {
    .gallery-container {
        width: 100%;
      }

      .main-image-wrapper {
        position: relative;
        width: 90%;
      }
      .main-image.enlarged {
        position: fixed;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
      width: 80vw;
      height: 90vh;
      object-fit: cover;
    }
}