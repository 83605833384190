.last-posts {
  width: 100%;
  padding: var(--xl-space) 0 var(--xxl-space) 0;
}

.last-posts h2 {
  text-align: center;
  padding: 0 1em;
  font-weight: 200;
  text-shadow: var(--text-shadow);
  color: var(--text-black);
}

.home-posts {
  margin: 0 auto;
  padding: var(--m-space);
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 2em;
}

.single-card {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 200px;
cursor: pointer;
  border-radius: var(--radius);
}

.single-card img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-info {
  display: flex;
  flex-direction: column;
  padding: var(--m-space) var(--s-space);
  background: var(--bg-gray);
  position: relative;
}

.card-info h4 {
  padding-top: var(--s-space);
}

.color-line-last-post {
  width: 100%;
  height: 14px;
  background: linear-gradient(304deg, #1b6d4d, #e26020 22%, #bb1d3d 93%);
}

#more-btn {
  width: auto;
  color: white;
  text-align: center;
}

.second-row-section-first {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
}

.second-row-section-second {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
}
.last-posts p,
.last-posts h3,
.last-posts h4 {
  text-shadow: var(--text-shadow);
}
.post-author{
  padding-bottom: 0.7em;
}
@media (max-width: 550px) {
  .last-posts h2 {
    font-size: 2.2em;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .single-card {
    flex-direction: row;
  }

  .single-card img {
    width: 40%;
    height: auto;
  }

  .color-line-last-post {
    display: none;
  }

  .card-info {
    justify-content: space-between;
  }

  .color-line-second {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: inline;
    height: 10px;
    background: linear-gradient(304deg, #1b6d4d, #e26020 22%, #bb1d3d 93%);
  }
  .home-posts {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2em 0;
    width: 95%;
    justify-content: center;
    align-items: flex-end;
  }

  .single-card {
    min-height: 120px;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0);
    cursor: pointer;
    overflow: hidden;

  }

  .single-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border-radius: 15px;
  }

  /* .single-card.card-1 {
    width: 98%;
    justify-content: center;
    height: 170px;
    border-radius: 15px;
    overflow: hidden;

    transition: transform 0.3s box-shadow 0.3s ease;
  } */
  .card-info {
    width: 36%;
  }

  .single-card:hover {
    border-radius: 15px;
    overflow: hidden;
  }

  .single-card {
    flex: 0 0 48%;
    justify-content: end;
    height: 170px;
    overflow: hidden;
    border-radius: 15px;
  }

  .single-card img {
    width: 55%;
    object-fit: cover;
  }

  .post-summary{
    text-align: center;
  }
  .post-author{
    text-align: center;
    padding-bottom: 0.7em;
  }
  .single-card.card-1 {
    width: 100%;
    flex: initial;
  }
  .single-card.card-2 {
    width: 100%;
    flex: initial;
  }
}
@media (min-width: 1200px) {
  .home-posts {
    width: 80%;
  }
  .single-card {
    flex-direction: row;
    align-self: center;
    width: 100%;
    border-radius: var(--radius);
    transition: box-shadow 0.3s ease, transform 0.7s ease; 
  }
  .single-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); 
    transform: scale(1.05);
  }
  .single-card img {
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    overflow: hidden;
  }
  .card-info {
    display: flex;
    flex-direction: column;
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
  .color-line-last-post {
    align-self: end;
  }
  .card-info {
    justify-content: space-evenly;
    flex: 0 0 50%;
  }
  .second-row-section-second {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .post-summary{
    text-align: center;
  }
  .post-author{
    text-align: center;
    padding-bottom: 0.7em;
  }
  .latest-description{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center;
    gap: 1em; */
  }
}

