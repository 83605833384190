  
  .motto-card {
    text-align: center;
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
    background-color: rgb(247, 247, 247);
    border-radius: 10px;
    border-bottom: 6px solid var(--orange);
    padding: 30px;
  }
  
  .motto-card > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--orange);
    font-weight: bold;
  }
  .motto-card > div .card-num {
    font-size: 66px;
    line-height: 0;
    margin-bottom: 30px;
  }
  
  .motto-card h2 {
    color: var(--green);
    margin-bottom: 0;
    font-size: var(--s-font-size);
  }
  .motto-card i {
    font-size: 42px;
    color: var(--green);
    margin: 20px;
    padding: 18px;
    border: 6px solid var(--orange);
    border-radius: 50%;
    text-align: center;
  }
  .motto-card > p {
    margin: 24px 0px;
    font-size: var(--xxs-font-size);
  }
  