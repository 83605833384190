.about {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: relative;
  padding: 0 220px 140px 220px;
  margin-top: 180px;
}

.about-content-img img {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  object-fit: cover;
}
.bg-about {
  position: absolute;
  top: 0;
  right: 240px;
  opacity: 0.05;
  z-index: -1;
}

.bg-about img {
  height: 450px;
}
.about-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  /* text-align: center; */
}

.about h3 {
  font-size: 24px;
  padding: 10px 0;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  border-bottom: 4px solid #1b6d4d;
  border-radius: 2px;
}

.about p {
  width: 90%;
  word-spacing: 2px;
}
.about button {
  margin-top: 20px;
}

#btn-suggestion {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .about {
    padding: 40px;
    padding-bottom: 80px;
    margin: 0;
  }
}

@media (max-width: 1280px) {
  .about-content {
    width: 100%;
  }
  .about-content-img {
    display: none;
  }
  .bg-about {
    right: initial;
  }
  .bg-about img {
    height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about {
    padding: 4em 0.5em;
    padding-left: 8%;
    margin: 0;
  }
  .about-content {
    padding-left: 40px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .about {
    padding: 4em 0.5em;
    margin: 60px;
  }
  .about-content {
    align-items: center;
    text-align: center;
  }
}
