.cookie-consent {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: var(--gravel);
    color: var(--blue);
    padding: 15px;
    text-align: center;
    z-index: 9999;
    display: none;
    border-radius: var(--radius);
}

.cookie-consent.show {
    display: block;
    animation: slide-up 1.5s ease-in-out;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.cookie-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cookie-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.cookie-consent p {
    margin: 0;
    padding: 5px 0;
    color: var(--blue);
}

.cookie-consent a {
    color: var(--blue);
    font-weight: bold;
}

.cookie-consent a:hover {
    color: var(--orange);
}

.accept-button {
    background-color: var(--green);
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 5px;
}

.accept-button:hover {
    background-color: darken(var(--green), 10%);
}
.accept-button {
    background-color: var(--green); 
    color: #fff; 
    border: none; 
    padding: 10px 20px; 
    margin: 10px 0; 
    cursor: pointer; 
    border-radius: 5px; 
    font-size: 16px; 
    text-transform: uppercase; 
    transition: background-color 0.3s ease; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.accept-button:hover {
    background-color: #007bff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); 
}

.accept-button:active {
    background-color: #007bff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transform: translateY(2px); 
}


@media (max-width: 767px) {
    .cookie-consent {
        width: 100%;
        left: 0;
        /* bottom: 6%; */
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .cookie-consent {
        width: 100%;
        left: 0;
        /* right: 2%; */
        padding: 15px 30px;
        /* bottom: 6%; */
    }
}

@media (min-width: 1200px) {
    .cookie-consent {
        width: 40%;
        left: auto;
        right: 2%;
        padding: 15px 30px;
        bottom: 5%;
    }
}
