body.active-sidebar .leaflet-container {
    pointer-events: none;
    /* Забраних движенията  с картата докато менюто е отворено */
}

body.active-sidebar .ad-sidebar-map {
    transform: translateX(0);
    z-index: 1006;
    transition: transform 1.2s ease-in-out;
}

.ad-sidebar-map {
    position: relative;
    background-image: url('../../../../public/images/sign-up/background.jpg');
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    z-index: 999;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1.2px solid rgba(0, 0, 0, 0.2);
    transition: transform 1.2s ease-in-out, height 1.2s ease-in-out;
}

.ad-close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    transition: opacity 1s ease-in-out;
}

.ad-close-button {
    cursor: pointer;
    position: absolute;
    padding: 5px;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    opacity: 0;
    pointer-events: all;
}

.active-sidebar .ad-close-button {
    pointer-events: all;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.ad-sidebar-content {
    width: 100%;
    opacity: 0;
    pointer-events: all;
    margin-bottom: 15px;
    transition: opacity 2s;
    overflow: hidden;
}

.active-sidebar .ad-sidebar-content {
    opacity: 1;
    pointer-events: visible;
    transition: opacity 2s;
}

.ad-sidebar-content h2 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    padding: 1em 0;
    background-color: var(--orange);
    white-space: nowrap;
    height: 25px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ad-user-map-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ad-user-map-info p {
    font-size: 14px;
}

.user-map-img {
    max-width: 120px;
    width: 100%;
    height: auto;
    object-fit: fill;
}

.map-desc-user {
    text-indent: initial;
    gap: 0.5em;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 10px;
}

.ad-map {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 20px;
    border-radius: var(--radius);
    background-color: var(--gravel);
    position: relative;
    box-shadow: var(--container-shadow);  
}

.ad-title {
    padding: 2px 0;
    text-align: center;
    text-shadow: var(--text-shadow);
    color: var(--grey);
    font-weight: 300;
}

.ad-scroll {
    display: flex;
    flex-direction: column;
    height: auto;
    z-index: 99999;
    transition: height 2s ease;
}

.ad-scroll h3 {
    text-align: center;
    margin: 35% auto;
    text-shadow: var(--text-shadow);
    color: var(--gray);
    font-weight: 300;
}

.ad-scroll-side-content-map {
    overflow-y: auto;
    height: calc(100% - 100px);
}

.ad-scroll-side-content-map::-webkit-scrollbar {
    width: 10px;
}

.ad-scroll-side-content-map::-webkit-scrollbar-track {
    background: transparent;
}

.ad-scroll-side-content-map::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border: 2px solid #f1f1f1;
    border-radius: var(--radius-input);
}

.ad-desc {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1.5em auto;   
    cursor: pointer;
}

.ad-map img {
    width: 160px;
    height: 160px;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    object-fit: cover;
    overflow: hidden;
    flex: 0 0 35%;
    cursor: pointer;
}

.ad-map h3 {
    margin: 0;
    font-size: var(--s-font-size);
    padding-bottom: 0.2em;
}

.ad-map h4 {
    font-size: var(--xs-font-size);
    border-bottom: 2px solid var(--text-black);
}

.ad-map-valid {
    padding-bottom: 0.4em;
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-shadow: var(--text-shadow);
    font-weight: 200;
}

.ad-map p {
    margin: 5px 0 0;
    font-size: 1em;
}

.ad-desc-map {
}

.ad-category {
    position: absolute;
    border-radius: var(--radius-input);
    right: 10px;
    top: -10%;
    background-color: var(--orange);
    padding: 0.5em;
    text-indent: initial;
    color: white;
    width: 70px;
    text-align: center;
    box-shadow: var(--container-shadow);
}

.active-sidebar .ad-sidebar-map {
    width: 100%;
    transition: width 4s, max-width 4s;
}

.color-lines {
    width: 100%;
    height: 5px;
    background: linear-gradient(304deg, #1B6D4D, #E26020 22%, #BB1D3D 93%);
}

.color-lines-pipe {
    width: 100%;
    height: 4px;
    background: linear-gradient(325deg, #ffffff 0%, #777777 50%, #ffffff 100%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.32);
}

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--orange);
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 24px;
}

.arrow-up-side-bar {
    color: var(--text-white) !important;
}

.scroll-to-top-button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

@media (min-width: 600px) {
    .ad-scroll {
        height: auto;
    }

    .ad-user-map-info {
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: 1200px) {
    .ad-sidebar-map {
        position: absolute;
        background-image: url('../../../../public/images/sign-up/background.jpg');
        left: 0;
        top: 0;
        width: 300px;
        height: 100%;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        overflow-y: auto;
        z-index: 9999;
        transform: translateX(-100%);
        display: flex;
        gap: 3rem;
        overflow: hidden;
        border-right: 1.2px solid rgba(0, 0, 0, 0.2);
        transition: transform 1.2s ease-in-out;
    }

    .ad-user-map-info {
        flex-direction: row;
    }

    .ad-user-map-info p {
        font-size: 16px;
    }

    .user-map-img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: fill;
    }

    .color-line {
        width: 100%;
        height: 8px;
        background: linear-gradient(304deg, #1B6D4D, #E26020 22%, #BB1D3D 93%);
    }

    .active-sidebar .ad-sidebar-map {
        max-width: 580px;
        width: 100%;
        transition: width 4s;
    }

    .ad-scroll {
        height: auto;
    }

    .ad-desc-user {
        flex: 0 0 65%;
        padding: initial;
    }

    .map-desc-user {
        margin: auto;
    }
}
