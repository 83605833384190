.all-users-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.all-users-container h2 {
  margin-bottom: 10px;
  font-size: var(--xs-font-size);
}
.all-users-table th img.table-icon {
  display: none; 
}
.all-users-container hr {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.all-users-table-container {
  max-height: 700px;
  overflow-y: auto;
}

.all-users-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: var(--container-shadow);
  font-size: var(--xxs-font-size);
  table-layout: fixed;
}

.all-users-table a {
  text-decoration: none;
  color: black;
  text-shadow: var(--text-shadow);
}

.all-users-table th,
.all-users-table td {
  padding: 8px;
  border: 1px solid #ddd;
  white-space: nowrap;
  text-align: left;
}

.all-users-table th {
  cursor: pointer;
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: var(--xs-font-size);
}

.all-users-table th:hover,
.all-users-table td:hover {
  background-color: #f1f1f1;
}

.all-users-table .number-cell {
  width: 70px;
}

.all-users-table tbody tr {
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-unapproved {
  padding: 6px 10px;
  margin: 2px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-second {
  background-color: var(--green-second);
}

.orange {
  background-color: var(--orange);
}

.red {
  background-color: var(--red);
}

.actions-admin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center;
  align-items: center;
  padding: 30px;
}

.all-users-table-container::-webkit-scrollbar {
  width: 10px;
}

.all-users-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.all-users-table-container::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid #f1f1f1;
  border-radius: var(--radius-input);
}

.search-container-all-users {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-container-all-users input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
}

.search-container-all-users select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.search-container-all-users button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.search-container-all-users .reset-icon-all-users {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
}

.no-result-fly {
  font-size: var(--m-font-size);
}

.comment-icon {
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.6s ease, filter 0.6s ease, box-shadow 0.3s ease;
}

.comment-icon:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.comment-icon:active {
  transform: scale(0.95);
  filter: brightness(0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.trimmed-email-all-users a {
  color: var(--orange);
  cursor: pointer;
}

.text-modal-overlay-all-users {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.text-modal-content-all-users-ads {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

.text-modal-content-all-users-ads .close-button-all-users {
  text-align: center;
  position: absolute;
  top: -80px;
  right: -20px;
  font-size: 40px;
  cursor: pointer;
  color: black !important;
  opacity: 1;
  background-color: var(--gravel);
  padding: 0px 10px;
  border-radius: 50%;
  text-decoration: none;
  text-indent: initial;
  transition: transform 0.3s ease-in-out;
}

.text-modal-content-all-users-ads .close-button-all-users:hover {
  transform: rotate(90deg);
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(-20px);
  }
  to {
      transform: translateY(0);
  }
}
.reset-icon-all-users{
  display: none;
}
@media (max-width: 767px) {
  .all-users-container h2 {
      font-size: var(--s-font-size);
  }

  .all-users-table .number-cell {
      width: 100%;
  }

  .id-table-admin {
      background-color: var(--gravel);
  }

  .all-users-table {
      font-size: var(--xs-font-size);
  }

  .all-users-table th,
  .all-users-table td {
      padding: 6px;
  }

  .all-users-table th {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  .all-users-table td {
      display: block;
      width: 100%;
      box-sizing: border-box;
  }

  .btn-unapproved {
      padding: 6px 8px;
      margin: 2px;
  }

  .actions-admin {
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;
  }

  .all-users-table tr {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }

  .all-users-table tr td.actions-admin {
      display: flex;
      justify-content: space-between;
      width: 100%;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .actions-admin {
      grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .all-users-container h2 {
      font-size: var(--s-font-size);
  }
.status-all-users{
  width: 155px;
}
  .ads-cell-all-users {
      width: 95px;
  }

  .registration-date-admin-all-users {
      width: 135px;
  }

  .th-email-all-users {
      width: 185px;
  }

  .all-users-table {
      font-size: var(--xxs-font-size);
  }

  .all-users-table th,
  .all-users-table td {
      padding: 10px;
      text-align: center;
  }

  .all-users-table th {
      font-size: var(--xxs-font-size);
      text-align: center;
  }

  .btn-unapproved {
      padding: 8px 12px;
      margin: 5px;
  }

  .actions-admin {
      gap: 10px;
  }

  .comment-icon {
      width: 30px;
      height: 30px;
  }


  .all-users-table th span {
      display: none; 
  }

  .all-users-table th img.table-icon {
      display: inline-block;
      width: 25px;
    
  }
  .ads-cell-all-users{
    width: 50px;
  }
  .reset-text-all-users {
    display: none;
  }
  .search-container-all-users .reset-icon-all-users {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (min-width: 980px) and (max-width: 1200px) {
  .all-users-container {
      width: 100%;
  }
}

@media (min-width: 1200px) {
  .all-users-container h2 {
      font-size: var(--l-font-size);
  }
  .all-users-table th img.table-icon {
    display: none; 
}
  .all-users-container {
      width: 100%;
  }

  .ads-cell-all-users {
      width: 100px;
  }

  .all-users-table {
      font-size: var(--xs-font-size);
  }

  .phone-number-all-suggest {
      width: 100px;
  }

  .all-users-table th,
  .all-users-table td {
      padding: 12px 15px;
      text-align: center;
  }

  .all-users-container {
      width: 100%;
  }

  .all-users-table th {
      font-size: var(--xs-font-size);
  }

  .btn-unapproved {
      padding: 10px 15px;
      margin: 10px;
  }

  .actions-admin {
      gap: 1em;
  }

  .comment-icon {
      width: 60px;
      height: 60px;
  }


  .all-users-table th span {
      display: inline-block; 
  }

  .all-users-table th img.table-icon {
      display: none; 
  }
  .reset-text-all-users {
    display: none;
  }
  .search-container-all-users .reset-icon-all-users {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .search-container-all-users {
      flex-direction: column;
      align-items: center;
  }
  .reset-text-all-users {
    display: block;
    margin-left: 0;
    background-color: var(--yellow);
    text-align: center;
    border-radius: var(--radius-input);
    padding: 0.5em;
  }
  .search-container-all-users input[type="text"],
  .search-container-all-users select,
  .search-container-all-users button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
  }

  .search-container-all-users button,
  .search-container-all-users .reset-icon-all-users {
      padding: 10px;
  }

  .search-container-all-users .reset-icon-all-users {
      align-self: flex-end;
      font-size: 1.2rem;
  }
  
}

@media (max-width: 450px) {
  .comment-icon {
      width: 40px;
      height: 40px;
  }
}

.legend-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend-item span {
  font-size: var(--xxs-font-size);
  color: var(--orange);
  text-decoration: none;
  align-items: center;
}

.legend-icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 1200px) {
  .legend-container {
      gap: 30px;
  }
}

@media (min-width: 800px) {
  .legend-container {
      gap: 25px;
  }
}

@media (max-width: 767px) {
  .legend-container {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
  }
}
