.unique-main-filters {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    max-width: 80%;
}


.my-masonry-grid {
    display: flex;
    width: 100%;
    gap: 1em;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

.my-masonry-grid_column > .unique-region-filter {
    margin-bottom: 1rem;
}

.unique-region-filter {
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 25px;
    position: relative;
    background-color: var(--green-cm);
    transition: max-height 0.2s ease, padding 0.2s ease;
    overflow: hidden;
    max-height: 60px;
}

.unique-region-filter.open {
    max-height: 800px;
    padding: 1rem;
}

.unique-select-container {
    display: block;
    position: relative;
}

.unique-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.unique-select-display {
    width: 90%;
    padding: 0.5rem;
    background-color: var(--green-cm);
    color: var(--text-white);
    border: 1px solid transparent;
    cursor: pointer;
    font-size: calc(var(--m-font-size) - 4px);
}

.unique-select-arrow {
    transition: transform 0.2s ease;
    color: #000;
    cursor: pointer;
    padding-right: 1rem;
}

.unique-select-arrow.open {
    transform: rotate(180deg);
}

.unique-options-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.unique-options-container.open {
    max-height: 800px;
    background-color: var(--green-cm-light);
    border-radius: 25px;
    padding: 0.5em;
}

.unique-option {
    padding: 0.5rem;
    background-color: var(--light-green-cm);
    color: var(--text-white);
    cursor: pointer;
    font-size: calc(var(--s-font-size) - 4px);
}

.unique-option:hover {
    background-color: var(--green-cm);
    color: var(--text-white);
    border-radius: var(--radius);
}

.unique-option.selected {
    background-color: var(--orange);
    color: var(--text-white);
}

.unique-skeleton {
    margin-bottom: 1rem;
    border-radius: 25px;
}

.unique-skeleton-option {
    margin-bottom: 0.5rem;
    border-radius: 15px;
}


@media (min-width: 768px) and (max-width: 1199px) {
    .unique-filters-community {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        grid-auto-rows: masonry;
    }

    .unique-region-filter {
        display: inline-block;
        width: 100%;
        margin-bottom: 1rem;
    }
    .unique-options-container.open{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}
@media (min-width: 1200px) {
    .unique-main-filters{
        margin-top: 6em;
        padding-bottom: 2em;
    }
    .unique-options-container.open{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
  
    .unique-select-display {
    font-size: calc(var(--m-font-size) );

    }
    .unique-region-filter{
        padding-bottom:4em;
    }
    .unique-option {
        font-size: var(--s-font-size);
    }
}