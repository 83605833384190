footer {
  width: 100%;

}

.footer {
  position: relative;
  width: 100%;
  background-color: var(--bg-black);
  color: var(--text-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  padding: var(--l-space) 0;
  overflow: hidden;
  opacity: 0.6;
}
.footer p {
  font-weight: 300;
  line-height: 1.4;
  color: var(--text-white);
}
.footer p a{
  font-weight: 300;
  line-height: 1.4;
  color: var(--text-white);
}
.footer h3 {
  padding-bottom: var(--s-space);
  cursor: pointer;
}
.footer-links a:hover {
  color: #ff6600;
  border-bottom: 2px solid #ff6600; 
  opacity: 1;
}
#root > footer > section > img {
  position: absolute;
  left: 2px;
  width: 160px;
  top: 5px;
  height: auto;
  z-index: 1;
  rotate: -20deg;
  filter: blur(1px);
  filter: brightness(70%);
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  margin-top: var(--m-space);
  text-align: center;
  z-index: 2;
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3em;
  text-align: center;
  z-index: 2;
}
.info-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.second-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.footer-info img {
  align-self: center;
  width: 50px;
  height: auto;
  overflow: hidden;
}

.footer-social {
  display: flex;
  gap: 0.5em;
  font-size: 2em;
  justify-content: center;
  padding: 0.7em 0;
  cursor: pointer;
}

.footer-privacy {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-black);
  color: var(--text-white);
  align-items: center;

  opacity: 0.8;
}

.footer-privacy p {
  font-size: var(--xs-font-size);
  padding: var(--s-space) 0;
  color: var(--text-white);
  text-align: center;
}
.footer-privacy a {
  font-size: var(--xs-font-size);
  padding: var(--s-space) 0;
  color: var(--text-white);
}
.footer-privacy a {
  font-size: var(--xs-font-size);
  padding: var(--s-space) 0;
  color: var(--text-white);
}
@media only screen and (max-width: 1200px) {
 .hide-on-mobile {
    display: block;
    /* position: sticky;
    bottom: 0; */
  }

}
@media (min-width: 850px) {
  .footer {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 3em;
  }
  
  .footer-info {
    flex: 0 0 20%;
  }

  .second-info {
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
  }

  .second-info h3 {
    text-align: start;
  }

  .footer-info img {
    width: 40px;
    height: auto;
  }

  .footer h3 {
    padding-bottom: initial;
  }

  .footer-links {
    margin-top: initial;
  }
}
