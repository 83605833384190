.modal-overlay-admin {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
  
  .new-comment-section {
    padding-top: 1em;
  }
  
  .modal-content-admin {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    max-width: 600px;
    transform: translateY(-50px);
    animation: slideIn 0.3s forwards;
  }
  
  .modal-close-admin {
    position: absolute;
    top: 2px;
    right: 10px;
    background: none;
    border: none;
    font-size: var(--m-font-size);
    cursor: pointer;
  }
  
  .modal-submit-admin {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--green-second);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--xs-font-size);
  }
  
  .modal-content-admin textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: var(--container-shadow);
    resize: none;
    height: 100px; 
  }
  
  .comments-section {
    max-height: 400px; 
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background: #f9f9f9;
    box-shadow: var(--container-shadow);
  }
  
  .comments-section::-webkit-scrollbar {
    width: 10px;
  }
  
  .comments-section::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .comments-section::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border-radius: var(--radius-input);
    border: none;
  }
  
  .comments-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .comments-list li {
    text-align: left;
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    word-wrap: break-word;
  }
  
  .comments-list li strong {
    color: var(--orange);
  }
  
  .suggestUsers-container-table-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .suggestUsers-container-table-container::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .suggestUsers-container-table-container::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border: 2px solid #f1f1f1;
    border-radius: var(--radius-input);
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    to {
      transform: translateY(0);
    }
  }
  
  @media (max-width: 1200px) {
    .modal-content-admin {
      max-width: 500px;
    }
  
    .modal-submit-admin {
      font-size: 14px;
    }
  
    .modal-content-admin h2 {
      font-size: var(--xs-font-size);
    }
  
    .modal-content-admin textarea {
      font-size: var(--xs-font-size);
      height: 60px;
    }
  }
  
  @media (min-width: 1200px) {
    .modal-submit-admin {
      font-size: var(--m-font-size);
    }
  
    .modal-content-admin h2 {
      font-size: var(--m-font-size);
    }
  
    .modal-content-admin textarea {
      font-size: var(--xs-font-size);
    }
  
    .modal-close-admin {
      font-size: var(--l-font-size);
    }
  }
  