.hero-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3em;
  overflow: hidden;
}

.parent-hero {
  min-height: 100vh;
  width: 100%;
  padding-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2em;
  margin-top: -170px;

}

.parent-hero::before {
  content: "";
  background-image: url("../../../../public/images/homePage/hero-img.jpg");
  background-size: cover;
  filter: grayscale(40%) blur(3px);
  background-position: top;
  position: absolute;
  top: 60px;
  width: 100%;
  height: 120%;
  z-index: -1;
}

.parent-hero::after {
  content: "";
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6391806722689075) 0%,
    rgba(255, 255, 255, 0.4543067226890757) 33%,
    rgba(255, 255, 255, 0.23862044817927175) 57%,
    rgba(255, 255, 255, 0) 79%
  );
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 60px;
  width: 100%;
  height: 120%;
  z-index: -1;
}

.parent-hero > div {
  width: 50%;
}
.parent-hero > div:first-child {
  margin-right: auto;
}
.parent-hero > div:last-child {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff54;
  backdrop-filter: blur(10px);
  height: 60vh;
  align-self: center;
  border-radius: 40px;
  width: 50%;
  margin-right: 0px;
  padding: 100px;
  
}

.left-side {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* max-width: 60%; */
  align-items: flex-start;
  padding-left: 160px;
}

.left-side h1 {
  font-size: 42px;
  color: var(--green);
  padding-bottom: var(--m-space);
  z-index: 50;
  line-height: 70px;
  font-weight: 800;
}

.left-side p {
  max-width: 90%;
  padding: 0;
  font-size: var(--xs-font-size);
}
.left-side p span {
  font-weight: 900;
  font-size: var(--s-font-size);
  text-shadow: var(--text-shadow);
}

.left-side p:first-child {
  margin-bottom: 10px;
  color: var(--orange);
  font-weight: 700;
  font-size: var(--s-font-size);
}

.right-side {
width:80%;
height: auto;
border-radius: 40px;
overflow: hidden;

}


.frame-container{
  width: 60%;
  height: 60%;
  /* position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; */
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  background-color: #000;
  border-radius: 40px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.frame-container .responsive-iframe {
  pointer-events: none; /* Скрива взаимодействието по подразбиране */
}

.frame-container:hover .responsive-iframe {
  pointer-events: auto; /* Разрешава взаимодействие при hover */
}
.btn-hero {
  display: flex;
  margin-top: var(--s-space);

  flex-direction: column;
}

.btn-hero :nth-child(1) {
  background-color: var(--green);
}

.btn-hero :nth-child(1):hover {
  opacity: 0.7;
}

.btn-hero :nth-child(2) {
  background-color: var(--orange);
}

.btn-hero :nth-child(2):hover {
  opacity: 0.7;
}

.hero-section .logo {
  margin-top: 3rem;
  display: block;
  width: 10em;
  height: auto;
  overflow: hidden;
}

.parent-motto {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10em;
}
.motto-img {
  margin-left: 10%;
  display: flex;
  justify-content: end;
  align-items: start;
  width: 70%;
  min-width: 550px;
}

.motto-img img {
  width: 100%;
  height: auto;
  max-height: 1070px;
  object-fit: contain;
}

.motto-desc {
  margin-right: 10%;
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: start;
  padding-bottom: 4rem;
}

.motto-info {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 1.2;
  gap: 1.5em;
}

.motto-info h3 {
  font-size: calc(var(--l-font-size) - 10px);
  text-align: center;
  text-shadow: var(--text-shadow);
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--green);
}

.motto-info p {
  max-width: 500px;
  text-align: left;
  font-weight: 500;
  padding: 0.25em 0.5em;
  font-weight: 10;
  letter-spacing: 1px;
  font-size: var(--s-font-size);
}

.motto-info span {
  font-weight: 900;
  font-size: var(--s-font-size);
}

#btn-join {
  width: auto;
  margin-top: 0;
  max-width: 500px;
  margin-top: 60px;
  text-align: center;
  text-transform: uppercase;
}

.hero-img-mobile {
  display: none;
}

.information-slide {
  width: 100%;
  margin-top: 76px;
  z-index: 10;
}

.information-slide marquee {
  padding: 10px 0;
    backdrop-filter: blur(10px);
    background-color: #ffffff94;
}

@media (max-width: 1280px) {
  .parent-hero {
    padding-top: 0;
    margin-top: 0px;
  }
  .hero-section {
    padding: 10px;
  }
  .hero-section h3 {
    font-size: 1.8rem;
  }
  .left-side {
    max-width:max-content;
    align-items: center;
    padding: 0;
    text-align: center;
  }
  .hero-section h1 {
    font-size: 2.1rem;
    line-height: 50px;
  }

  .left-side p {
    max-width: 60ch;
  }
  .parent-hero::after {
  background: rgba(255, 255, 255, 0.33);
  }
}

@media (max-width: 1400px) {
  .parent-hero {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 6em;
  }

  .hero-section .logo {
    display: none;
  }

  .parent-motto {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn-hero {
    flex-direction: row;
    gap: 2em;
  }

  .motto-img {
    display: none;
  }
  .hero-img-mobile {
    display: block;
    width: 66%;
  }

  .motto-info > * {
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .left-side {
    padding: 4em 0.5em;
    padding-left: 7%;
  }

}

@media(max-width: 1024px) {
  .parent-hero {
     flex-direction: column;
  }

  .parent-hero > div:first-child {
    width: 90%;
    margin-right: 0;
}


.parent-hero > div:last-child {
  width: 100%;
  margin-right: 0px;
  padding: 0px 30px;
  height: 30vh;

}

.parent-hero::before {
  height: 100%;
}

.information-slide {
  margin-top: 70px;
}
}
