.subscription-container {
    width: 90%;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .search-container-subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .search-input-subscription {
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .search-select-subscription {
    width: 20%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-left: 10px;
  }
  
  .search-actions-subscription {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;


  }
  .search-actions-subscription span{
    background-color: var(--yellow);
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--text-white);
    border: none;
    cursor: pointer;
    font-size:var(--xxs-font-size) ;
  }
  .search-button-subscription {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #007bff;
    color: var(--text-white);
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .reset-icon-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .reset-icon {
    cursor: pointer;
  }
  
  .reset-text {
    display: none;
    cursor: pointer;
  }
  
  .emails-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .emails-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    align-items: start;
  }
  
  .email-wrapper {
    border-bottom: 1px solid #ccc;
  }
  
  .email-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .email-row:hover {
    background-color: #f9f9f9;
  }
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-button {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    margin: 0 10px;
  }
  
  @media (min-width: 768px) and (max-width: 1200px) {
    .search-actions-subscription {
      flex-direction: row;
    }
    .emails-header {
        flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      }
      .email-row {

        flex-direction: row;
        gap: initial;
      }
  }
  
  @media (max-width: 650px) {
    .search-container-subscription {
      flex-direction: column;
    }

    .search-input-subscription {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .search-select-subscription {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .search-button-subscription {
      padding: 5px 10px;
    }
  
    .pagination-button {
      padding: 5px 10px;
    }
  
    .reset-icon {
      display: none;
    }
  
    .reset-text {
      display: block;
      margin-left: 0;
    }
  
    .reset-icon-container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (min-width: 1200px) {
    .search-actions-subscription {
      flex-direction: row;
    }
    .emails-header {
      flex-direction: row;
    justify-content: space-between;
    align-items: center;

    }
    .email-row {

        flex-direction: row;
        gap: initial;
      }
  }
  