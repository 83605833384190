/* .background-ads-details {
  background-image: url('../../../../../public/images/community/community-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
} */

.side-bar-positron {
  position: relative;
}

.ads-details-page {
  width: 100%;
  min-height: 100vh;
}

.share-button-ad {
  display: flex;
  flex-direction: column;
}

.share-options {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.hero-bg-details {
  display: none;
  position: relative;
}

.hero-section-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ads-details-page h1 {
  display: none;
  margin: 0;
  position: absolute;
  top: 5%;
  left: 39%;
  color: var(--text-white);
  font-size: var(--l-font-size);
  letter-spacing: 1.7rem;
  padding: initial;
}

/* .hero-section-details .group-icon p {
  display: none;
} */

.hero-section-details .elipse.price {
  display: block;
  color: var(--green);
  font-size: var(--m-font-size);
  font-weight: 700;
  margin-top: 0px;
}

.ads-details-icons p {
  font-size: var(--xxs-font-size);
  color: var(--text-black);
}

.ads-details-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-indent: initial;
  gap: 2em;
}

.ads-details-container {
  position: relative;
  padding: 0.9em 0;
  border-radius: var(--radius);
  transition: transform 0.7s ease, box-shadow 0.6s ease;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ads-details-icons {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin: var(--l-space) 0; */
  margin-bottom: 1em;
  gap: 2px;
  background-color: var(--bg-gray);
}

.ads-details-icons a {
  height: 100%;
  background-color: var(--bg-white);
}

.ads-details-icons a:hover {
  background-color: var(--bg-gray);
}

.ads-details-container .ads-details-icons .group-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 10px 0;
}

.ads-details-icons .group-icon .icon {
  color: var(--green) !important;
  width: 15%;
  height: 15%;
  text-align: center;
}

.group-icon:hover {
  color: var(--yellow) !important;
}

.ads-details-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
}

.ads-details-desc {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: var(--s-space);
  margin: var(--l-space) 0;
  align-self: center;
}

.title-details {
  font-size: 36px;
  font-weight: 600;
}

.ads-details-desc h5 {
  font-size: var(--xs-font-size);
  font-weight: 400;
}

.ads-details-desc hr {
  width: 100%;
  background-color: var(--bg-gray);
  height: 2px;
  border: none;
}

.img-ads-details {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
}

.main-image-wrapper > img {
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  height: 260px;
  border-radius: var(--radius);
  margin-top: 1em;
  overflow: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
  cursor: pointer;
  position: relative;
  padding: 20px;
}

.small-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* width: 100%; */
  justify-content: flex-start;
  gap: 1em;
  padding: 0 20px;
  background-color: var(--bg-gray);
}

.small-img img {
  width: 100%;
  border-radius: 12px;
}

/* .img-ads-details p {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  display: inline;
  color: var(--text-white);
  font-size: var(--s-font-size);
  border: 2px solid var(--yellow);
  border-radius: var(--radius);
  padding: 0.1em 1.5em;
  padding-bottom: 0.3em;
  top: 116px;
  left: 2%;
  background-color: var(--green-text);
} */

.ads-details-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* gap: 70px; */
  width: 100%;
  /* color: var(--text-white); */
  padding: 0 1em;
}

.ads-details-info h3 {
  /* color: var(--text-white); */
  font-size: var(--l-font-size);
  padding-top: 0.6em;
  text-shadow: var(--text-shadow);
}

.ads-details-desc h4 {
  font-size: var(--xs-font-size);
  /* color: var(--text-white); */
  font-style: italic;
}

.ads-details-desc h3 {
  /* color: var(--text-white); */
  font-size: var(--s-font-size);
  padding-top: 0.6em;
  text-shadow: var(--text-shadow);
  /* align-self: center; */
  font-weight: 500;
}

.subinfo-ads-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8em;
  flex-wrap: wrap;
}

.tags-details-mapping {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  width: 100%;
  padding: 20px 0;
  justify-content: center;
}

.tags-details-mapping h5 {
  color: var(--green-text);
  background-color: var(--grey-bg);
  padding: 0.3em 1.2em;
  border-radius: var(--radius);
  font-size: calc(var(--xs-font-size) + 2px);
  text-shadow: var(--text-shadow);
  text-transform: capitalize;
  margin-bottom: 0.2em;
  font-weight: 600;
}

.ads-details-user-info {
  padding-bottom: 1.5em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  /* color: var(--text-white); */
  text-shadow: var(--text-shadow);
}

.ads-details-username {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 1em;
  align-items: center;
}

.ads-details-user-info a {
  text-decoration: none;
  color: var(--text-black);
}
.ads-details-username span {
  font-size: 20px;
  font-weight: 550;
}

.ads-details-data {
  padding: 0 1em;
}

.ads-details-user-info p {
  text-transform: capitalize;
}

.ads-details-user-info img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  /* box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1); */
  border: 4px solid var(--orange);
  padding: 4px;
}

.user-info-details {
  background-color: var(--bg-gray);
  width: 100%;
  border-radius: var(--radius);
}

.ads-details-user-info {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  gap: 1.5em;
  padding: 20px;
  justify-content: space-between;
}

.details-underlined {
  font-size: calc(var(--xs-font-size));
  /* text-decoration: underline; */
  /* color: var(--text-white); */
}

.ad-details-back-phone {
  position: absolute;
  top: 90px;
  left: 40px;
  z-index: 100;
}

.ad-details-back-phone p {
  font-size: var(--l-font-size);
}

.ad-details-back-phone p > * {
  color: var(--green-cm-light) !important;
}

.ads-details-back {
  display: none;
}

.position-category {
  position: relative;
}

.position-category .gallery-container,
.position-category .main-image-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0;
  background-color: var(--bg-gray);
}

.position-text-absolute {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  display: inline;
  color: var(--bg-white);
  font-size: var(--xs-font-size);
  font-weight: 600;
  border-radius: var(--radius);
  padding: 0.6em 1.5em;
  top: 40px;
  right: 40px;
  background-color: var(--orange);
  z-index: 9;
}

.subinfo-ads-details svg {
  margin-right: 10px;
}

p.elipse {
  font-weight: 600;
  color: var(--orange);
}

.ads-details-data span {
  font-weight: 550;
  font-size: 20px;

}

@media (max-width: 360px) {
  .ads-details-icons {
    flex-direction: column;
    gap: 0.7em;
  }
}

@media (min-width: 550px) and (max-width: 767px) {
  .position-text-absolute {
    left: 23%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .position-text-absolute {
    left: 12%;
    top: -20px;
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .position-text-absolute {
    left: 22%;
    top: -20px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .ads-details-main {
    margin: 0 auto;
    width: 81%;
  }

  .ads-details-icons .group-icon .icon {
    width: 15%;
    height: 15%;
  }

  .ads-details-icons p {
    display: block;
    /* color: var(--text-white); */
    font-size: var(--s-font-size);
    font-weight: 400;
  }

  .ads-details-card {
    align-items: flex-start;
    flex-direction: row;
    gap: 0;
    padding-bottom: 20px;
  }

  .img-ads-details {
    display: flex;
    flex-direction: column;
    gap: 2em;
    flex: 1 1 0;
    padding: 0 1em;
  }

  .img-ads-details img {
    margin-top: initial;
    width: 100%;
    max-width: 460px;
    height: 220px;
    object-fit: cover;
  }

  /* 
  .img-ads-details p {
    top: 183px;
    left: 6%;
    font-size: calc(var(--xs-font-size) + 2px);
    padding: 0.3em 1em;
  } */

  .small-img {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 1em;
  }

  .small-img img {
    width: 50px;
    height: 50px;
  }

  .ads-details-info h3 {
    font-size: var(--m-font-size);
  }

  .ads-details-desc h3 {
    font-size: var(--m-font-size);
  }

  .ads-details-info {
    padding: 0 2em;
    flex: 1 1 0;
    gap: 20px;
  }

  .ads-details-user-info {
    gap: initial;
  }

  .ads-details-icons .group-icon .icon {
    width: 15%;
    height: 15%;
  }

  .ads-details-icons .group-icon p {
    display: block;
  }

  .ads-details-back {
    display: none;
  }

  .ad-details-back-phone {
    padding-left: 32px;
  }
}

@media (min-width: 1200px) {
  .ads-details-main {
    margin: 3em auto;
    margin-top: 0;
  }

  .ads-details-page h1 {
    display: block;
  }

  .ads-details-card {
    margin: 0 auto;
    flex-direction: row;
    align-items: initial;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .ads-details-info h3 {
    font-size: var(--l-font-size);
  }

  .ads-details-desc h3 {
    font-size: var(--s-font-size);
    font-weight: 600;
  }

  .subinfo-ads-details {
    gap: 1.8em;
  }

  .subinfo-ads-details p {
    font-size: calc(var(--m-font-size) - 2px);
  }

  .ads-details-data {
    font-size: calc(var(--m-font-size) + 2px);
  }

  .ads-details-user-info p {
    font-size: calc(var(--m-font-size) + 2px);
  }

  .main-details {
    max-width: 1280px;
    margin: 0 auto;
    /* background-color: var(--yellow); */
    border-radius: 15px;
  }

  .hero-section-details h1 {
    margin: 0;
    position: absolute;
    top: 25%;
    /* color: var(--text-white); */
    font-size: var(--l-font-size);
    letter-spacing: 1.7rem;
    padding: initial;
  }

  .hero-section-details p {
    display: block;
    /* color: var(--text-white); */
    /* font-size: var(--s-font-size); */
    font-weight: 400;
    font-size: 20px;
  }

  .subinfo-ads-details-details p {
    font-size: calc(var(--m-font-size) - 2px);
    color: var(--text-black);
  }

  p.elipse {
    font-weight: 600;
    color: var(--orange);
  }

  .after-header {
    display: none;
  }

  .ads-details-card {
    border-color: var(--green-cm);
    border-width: 5px;
  }

  .ads-details-info {
    flex: 1 1 0;
    gap: 0px;
  }

  .ads-details-icons .group-icon .icon {
    width: 15%;
    height: 15%;
  }

  .ads-details-icons .group-icon p {
    display: block;
    font-size: var(--xs-font-size);
    color: black;
  }

  .img-ads-details {
    flex: 1 1 0;
    position: sticky;
    top: 80px;
    align-self: flex-start;
  }

  .img-ads-details .position-category p.position-text-absolute {
    top: 10px;
    left: auto;
    padding: 14px 68px;
    font-weight: 550;
    font-size: var(--xs-font-size);
  }

  .img-ads-details img {
    width: 100%;
    height: 400px;
  }

  .small-img {
    /* display: flex; */
    width: 100%;
    justify-content: flex-start;
    gap: 1em;
  }

  .small-img img {
    /* width: 150px; */
    height: 150px;
    object-fit: cover;
  }

  .ads-details-user-info img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    /* box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1); */
    border: 4px solid var(--orange);
    padding: 4px;
  }

  .user-info-details {
    /* max-width: 350px; */
    margin-top: auto;
  }

  .ads-details-back {
    display: block;
    width: 100%;
    font-size: var(--s-font-size);
    color: var(--brown);
    text-align: left;
    margin-top: 4em;
    margin-bottom: 0.5em;
  }

  .ads-details-back > * {
    color: var(--brown) !important;
  }

  .ads-details-back a {
    font-size: ;
  }

  .ad-details-back-phone {
    display: none;
  }

  .small-img {
    margin-left: initial;
    background-color: var(--bg-white);
  }

  .ads-details-icons p {
    font-size: var(--s-font-size);
  }

  .position-category .gallery-container,
  .position-category .main-image-wrapper {
    background-color: var(--bg-white);
  }

  .ads-details-container {
    padding: 0;
    /* overflow: hidden; */
  }
}


@media (max-width: 1024px) {
  .position-text-absolute {
    top: 60px;
    right: 10px;
    font-size: 16px;
  }
  .ad-details-back-phone {
    left: 10px;
    top: 80px;
  }

  .gallery-container {
    padding-top: 40px;
  }

  .small-img {
    gap: 0.5em;
  }
  .small-img img {
    height: 80px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  .ads-details-user-info {
    flex-direction: column;
    gap: 10px;
  }

  .ads-details-data span {
    font-weight: 500;
    font-size: 20px;
  }

  .title-details {
    font-size: 30px;
}
.ads-details-icons {
  flex-direction: column;
}
.ads-details-icons a {
  width: 100%;
}

.ads-details-icons .group-icon .icon {
  width: 40px;
}
}