@import "~leaflet-fullscreen/dist/leaflet.fullscreen.css";

.map-container {
    position: relative;
}

.ad-card-editor {

    text-indent: initial;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 1em;
    padding-bottom: 1em;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 250px;

}

.leaflet-popup-content-wrapper {
    box-shadow: var(--container-shadow);
    border: 2px inset var(--orange);
    border: 2.5px outset var(--orange);
}

.leaflet-popup-close-button span {
    font-size: var(--s-font-size);
    color: black;
    font-weight: 500;

}

.city-label {
    font-size: 40em;
}

.leaflet-container a.leaflet-popup-close-button {
    top: 4px;
    right: 4px;

}

.ad-details-editor {
    flex: 2;

}

.ad-name-editor {
    margin: 0;
    font-size: var(--xxs-font-size);
    font-weight: bold;
    text-align: center;
    padding-bottom: 1em;
}

.ad-img-editor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: fill;
}

.leaflet-top.leaflet-left {
    left: auto;
    right: 10px;
    z-index: 400;
}

.ad-description-editor {
    font-size: var(--xxs-font-size);
}

#read-more-editor {
    justify-content: center;
    display: flex;
    align-self: center;
    color: white;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 10px;
    /* background: linear-gradient(270deg, #f3811f, #e72725); */
    background-color: var(--red-bg-button);
    text-decoration: none;
}

.custom-marker-cluster {
    height: 3em !important;
    width: 3em !important;
    border-radius: 50%;
    background-color: var(--red);

    transform: translate(-25%, -25%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    border: 2px outset var(--orange);
    box-shadow: var(--cluster-red-shadow);

    opacity: 0.9 !important;
}
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1010;
}
/* img modal */
.image-modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.image-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
}

.image-modal-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
@media (min-width: 850px) {
    .ad-card-editor {
        max-width: calc(550px / (340px / var(--container-min-width)));
    }

    .ad-img-editor {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    .ad-name-editor {
        font-size: var(--s-font-size);

    }
}