.pending-announcements-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.pending-announcements-container h2 {
  margin-bottom: 10px;
  font-size: var(--xs-font-size);
}

.pending-announcements-container hr {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.pending-announcements-table-container {
  max-height: 700px;
  overflow-y: auto;
}

.pending-announcements-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: var(--container-shadow);
  font-size: var(--xxs-font-size);
  table-layout: fixed;
}

.pending-announcements-table a {
  text-decoration: none;
  color: black;
  text-shadow: var(--text-shadow);
}

.pending-announcements-table th,
.pending-announcements-table td {
  padding: 8px;
  border: 1px solid #ddd;
  white-space: nowrap;
  text-align: center;
}

.pending-announcements-table th {
  cursor: pointer;
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
}

.pending-announcements-table th:hover,
.pending-announcements-table td:hover {
  background-color: #f1f1f1;
}

.pending-announcements-table .number-cell {
  width: 70px;
}

.pending-announcements-table tbody tr {
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-unapproved {
  padding: 6px 10px;
  margin: 2px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-second {
  background-color: var(--green-second);
}

.orange {
  background-color: var(--orange);
}

.red {
  background-color: var(--red);
}

.actions-admin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  justify-items: center; 
  align-items: center;
  padding: 30px;
}

.pending-announcements-table-container::-webkit-scrollbar {
  width: 10px;
}

.pending-announcements-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.pending-announcements-table-container::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid #f1f1f1;
  border-radius: var(--radius-input);
}

.search-container-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-container-pending input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  margin-right: 10px;
}

.search-container-pending select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.search-container-pending button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.search-container-pending .reset-icon-pending {
  font-size: 1.5rem;
  color: var(--green);
  cursor: pointer;
  display: none;
}

.no-result-fly {
  font-size: var(--m-font-size);
}

.comment-icon {
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.6s ease, filter 0.6s ease, box-shadow 0.3s ease;
}

.comment-icon:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.comment-icon:active {
  transform: scale(0.95);
  filter: brightness(0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.trimmed-tittle-pending a,
.trimmed-email-pending a {
  color: var(--orange);
  cursor: pointer;
}

.text-modal-content-approved-ads {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

.text-modal-content-approved-ads .close-button-approved {
  text-align: center;
  position: absolute;
  top: -80px;
  right: -20px;
  font-size: 40px;
  cursor: pointer;
  color: black !important;
  opacity: 1;
  background-color: var(--gravel);
  padding: 0px 10px;
  border-radius: 50%;
  text-decoration: none;
  text-indent: initial;
  transition: transform 0.3s ease-in-out;
}

.text-modal-content-approved-ads .close-button-approved:hover {
  transform: rotate(90deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
.reset-icon-pending{
  display: none;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .pending-announcements-table th img.table-icon {
    display: inline-block;
    width: 25px;
  }
  .pending-announcements-table th span {
    display: none;
  }
  .reset-text-pending {
    display: none;
  }
  .search-container-pending .reset-icon-pending {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }
}

@media (max-width: 767px), (min-width: 1201px) {
  .pending-announcements-table th img.table-icon {
    display: none;
  }
  .pending-announcements-table th span {
    display: inline;
  }
  /* .search-container-pending .reset-icon-pending {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  } */
}

@media (max-width: 767px) {
  .pending-announcements-container h2 {
    font-size: var(--s-font-size);
  }
  .pending-announcements-table .number-cell {
    width: 100%;
  }
  .pending-announcements-table {
    font-size: var(--xs-font-size);
  }
  .pending-announcements-table th,
  .pending-announcements-table td {
    padding: 6px;
  }
  .pending-announcements-table th {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pending-announcements-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  .btn-unapproved {
    padding: 6px 8px;
    margin: 2px;
  }
  .actions-admin {
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
  }
  .pending-announcements-table tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pending-announcements-table tr td.actions-admin {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

.search-container-pending {
  flex-direction: column;
  align-items: center;
}
.reset-text-pending {
  display: block;
  margin-left: 0;
  background-color: var(--yellow);
  text-align: center;
  border-radius: var(--radius-input);
  padding: 0.5em;
}
.search-container-pending input[type="text"],
.search-container-pending select,
.search-container-pending button {
  width: 100%;
  margin-right: 0;
  margin-bottom: 10px;
}

.search-container-pending button,
.search-container-pending .reset-icon-pending {
  padding: 10px;
}


}

@media (max-width: 450px) {
  .comment-icon {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1200px) {
  .legend-container {
    gap: 30px;
  }

  .search-container-pending .reset-icon-pending {
    font-size: 1.5rem;
    color: var(--green) !important;
    cursor: pointer;
    display: inline-block;
  }

  .reset-text-pending {
    display: none;
  }
}
