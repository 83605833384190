.background-community {
    background-image: url('../../../public/images/community/community-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.community-page {
    width: 100%;
    min-height: 100vh;

}

.hero-bg {
    display: none;
}
.hero-section-commun {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:  0 2em;
}

.hero-section-commun h1 {
    /* margin-top: 3em; */
    text-align: center;
    color: var(--green);
    font-size: calc(var(--m-font-size) + 10px);
    /* line-height: 46.88px; */
    letter-spacing: 0.5rem;
    /* padding-left: 1.2rem; */
    font-weight: 800;

}

.hero-section-commun p {
    display: none;
}

.header-commun {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.header-commun img {
    width: 20%;
    height: auto;
    object-fit: contain;
    opacity: 0.5;
}
.search-bar-commun-s {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0.8em 0.4em;
    width: 100%;
    /* max-width: 400px; */
    /* border: 2px var(--green-cm-light) solid; */
    border-radius: var(--radius);
    background-color: var(--bg-gray);
}

.search-bar-commun-s .icons-com svg {
    margin-right: 0;
}

.divider {
    width: 1px;
    height: 35px;
    background-color: var(--green-cm-light);
    margin: 0 0.5em;
}

.commun-icon {
    color: var(--green-cm-light) !important;
    font-size: calc(var(--s-font-size) - 2px);
    margin-right: 0.5em;
}

.icons-com {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

}

.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--yellow);
    border: none;
    color: white;
    font-size: var(--xs-font-size);
    padding: 0.5em 1.5em;
    border-radius: var(--radius);
    cursor: pointer;

    margin-left: 1.5em;
}
.reset-icon {
    margin-left: 2px;
    font-size:calc(var(--xs-font-size) + 3px);
    cursor: pointer;
    color: var(--green-text)!important;
    font-weight: 100 !important;

}
.no-ads-container{
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    color: var(--text-white);
    /* padding: 1em; */
}
.no-ads-container h3{
    background-color: var(--green-second);
    padding: 22px 24px 22px 24px;
    text-align: center;
    border-radius: var(--radius);
    font-size: var( --m-font-size);
}
.no-ads-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--green-text);
    border-radius: var(--radius);
    padding: 1.6em 1em 1.6em 0.8em;
    gap: 1em;
}
.no-ads-message p{
    font-size: calc(var(--s-font-size) - 4px);
    text-align: center;
}
.clear-filters-button{
    background-color: var(--brown);
    padding: 10px 30px 10px 30px;
    border-radius: var(--radius);
    color: var(--text-white);
    font-size: calc(var(--s-font-size) - 4px);

}

.clear-filters-button:hover {
    cursor: pointer;
}

.search-category-container {
    display: none;
}

.search-bar-commun-s button {
    margin-top: 0;
    padding: 14px 24px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .search-bar-commun-s {
        max-width: 75%;

    }
    .reset-icon {
        margin-right: 10px;
        font-size:calc(var(--xs-font-size) + 10px);
    
    }
    .icons-com {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .hero-section-commun p {
        display: block;
        color: var(--green-text);
        font-size: var(--xs-font-size);
        font-weight: 800;
    }
    .no-ads-container{
        width: 60%;
        /* padding: 1em; */
    }
}

@media (min-width: 1024px) {
    .main-community {
        width: 80%;
        margin: 0 auto;
        /* background-color: var(--yellow); */
        border-radius: 15px;
    }

    .community-page {
        padding: 2em 0;
        flex-direction: row;
        display: flex;
    }

    .search-category-container {
        display: flex;
        width: 30%;
        padding: 3rem;
        position: sticky;
        top: 100px;
        align-self: flex-start;
    }
        
    .search-category-content h2 {
        font-size: var(--s-font-size);
        margin-bottom: 30px;
    }

    .search-category-container .category-select {
        display: flex;
        flex-direction: column;
    }

    .category-select label {
        display: flex;
        flex-direction: row-reverse;
        margin-right: auto;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: var(--xxs-font-size);
        text-transform: uppercase;
    }

    .search-bar-commun-s button {
        margin-top: 0;
        padding: 14px 60px;
    }

    .category-select label input {
        appearance: none;
        width: 24px;
        height: 24px;
        padding: 2px;
        border: 4px solid var(--green);
        /* border-radius: 50%; */
        outline: none;
        cursor: pointer;
        background-color: #fff;
        margin-right: 12px;
    }

    .category-select label input:checked {
        background-color: var(--orange);
        border-color: var(--green);
    }

    .hero-bg {
        display: none;
    }

    .hero-section-commun h1 {
        margin: 0;
        top: 25%;
        font-size: 38px;
        letter-spacing: 1rem;
        padding: initial;
        font-weight: 900;
    }

    .search-bar-commun-s {
        /* position: absolute;
        width: 1200px; */
        max-width: initial;
        /* background-color: var(--bg-white); */
        padding: 1.2em 1.8em;
        gap: 0;
    }

    .commun-icon {
        font-size: var(--m-font-size);

    }

    .search-button {
        font-size: var(--m-font-size);
        margin-left: 5.5em;

    }

    .icons-com {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .hero-section-commun p {
        display: block;
        color: var(--green-text);
        font-size: var(--s-font-size);
        font-weight: 800;
    }

    .divider {
        height: 50px;
    }

    .after-header {
        display: none;
    }
    .reset-icon {
        /* margin-left: 10px; */
        font-size:calc(var( --l-font-size) - 4px);
        cursor: pointer;
        color: var(--green-text)!important;
        font-weight: 100 !important;
    
    }
    
    .reset-icon:hover {
        color: #0056b3;
    }
    .no-ads-container{
        width: 60%;
       margin-top: 6em;
       padding-bottom: 7em;
    }
    .no-ads-container h3{
        font-size: var(--m-font-size);
    }
    .no-ads-container p{
        font-size: calc(var(--s-font-size));
    }
    .clear-filters-button{
        margin: 10px 0;
        font-weight: 600;
        font-size: calc(var(--s-font-size));

    }
    .header-commun {
        margin-bottom: 18px;
    }

    .header-commun img {
        width: 100px;
    }
}