.custom-select-with-checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-select-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.custom-select-icon {
    margin-right: 8px;
}

.custom-select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: var(--m-space);
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.custom-select-header span:not(.custom-select-chevron) {
    /* flex-grow: 1; */
   color: var(--black);
   font-weight: 400;
}

.custom-select-chevron {
    /* margin-left: 8px; */
    right: calc(var(--s-space) + 0.1rem);
    color: var(--bg-orange)
}

.custom-select-dropdown {
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.select-all-clear {
    display: flex;
    justify-content:space-evenly;
    padding: 5px;

    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.select-all-clear button {
    background: rgb(226, 96, 32);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
    text-decoration: none;
    
}

.select-all-clear button:hover {
    background: rgb(226, 96, 32);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}



.checkbox-item {
    margin-bottom: 4px;
}

.checkbox-item label {
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: flex-start;
    margin-bottom: 4px; 
}

.checkbox-item-custom input[type="checkbox"] {
    margin-right: 8px; 
    width: auto;
    box-shadow: none;
    width: 20px;
    height: 20px;
}


.checkbox-item label span {
    flex-grow: 1; 
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    color: rgb(226, 96, 32);
}

.checkbox-item.checked {
    background-color: rgb(239, 185, 158); 
}