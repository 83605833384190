.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: rgba(255, 255, 255, 0.9); 
    padding: 20px;
    border-radius: 10px;
    box-shadow:var(  --container-shadow); 
    position: relative;
    max-width: 400px; 
    text-align: center;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease-in-out; 
}


@keyframes modalOpen {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes modalClose {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.7);
    }
}

.modal-overlay.show {
    animation: modalOpen 0.3s forwards;
}

.modal-overlay.hide {
    animation: modalClose 0.3s forwards;
}
