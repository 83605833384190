.all-announcements {
    display: flex;
    flex-direction: column;
    gap: 6em;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 1600px;
    overflow: hidden;
    padding-bottom: 2em;
}

.all-announcements h2 {
    font-size: var(--l-font-size);
    text-align: center;
    margin-top: 1rem;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--orange);
    text-decoration: none;
    text-shadow: var(--container-shadow);
}
.bar-chart{
    display: flex;
    flex-direction: column;
}
.line-chart{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pie-chart{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.all-announcements h3 {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.chart-row {
    display: flex;
    gap: 2em;
    flex-direction: column;
}

.recharts-legend-item-text {
    cursor: pointer;
}

.recharts-responsive-container {
    width: 100%;
    height: 400px; 
}

.custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.tooltip-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.tooltip-item {
    font-size: 14px;
    color: #666;
    margin: 0;
}

@media (min-width: 1200px) {
 
    #root > section.profile-section > div > div > div.chart-row > div:nth-child(1){
        width: 50%;
    }
    .all-announcements {
        height: 100%;
    gap: 3em;
    }
    .chart-row {
        display: flex;
        gap: 2em;
        flex-direction: row;
    }
    .bar-chart{
        display: flex;
        flex-direction: column;
    }
    .line-chart{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .pie-chart{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
}

.chart-container {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
}

.charts-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.chart-container.full-width {
    width: 100%;
    height: 400px;
}

.chart-container.half-width {
    width: 49%;
    height: 300px;
}

.chart-container h3 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
}

.recharts-legend {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.recharts-legend-item {
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.recharts-legend-item-symbol {
    margin-right: 5px;
}

.recharts-legend-item-text {
    font-size: 14px;
    color: #555;
}

.recharts-wrapper {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.recharts-bar {
    transition: all 0.3s ease;
}

.recharts-bar:hover {
    filter: brightness(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.recharts-line {
    transition: all 0.3s ease;
}

.recharts-line:hover {
    stroke-width: 3px;
    filter: brightness(1.1);
}

.recharts-pie {
    transition: all 0.3s ease;
}

.recharts-pie-slice {
    transition: all 0.3s ease;
}

.recharts-pie-slice:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chart-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.stats {
    text-align: center;
    margin-top: 20px;
    color: #555;
}
