.forget-pass {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url("../../../public/images/sign-up/selfie-seniors2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: var(--xl-space) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-indent: initial;
}

.forget-pass-container {
    margin: 6em auto 2em auto;
    padding: 2em;
    display: flex;
    max-width: 600px;
    gap: 1em;
    height: 100%;
    flex-direction: column;
    border: 2px solid var(--gravel);
    background-color: var(--bg-white);
    box-shadow: 1px 1px 1px 0 var(--subtle);
}

.forget-pass-container h2 {
    text-shadow: var(--text-shadow);
    color: var(--orange);
}

.forget-pass-container p {
    font-size: calc(var(--xs-font-size) + 2px);
    opacity: 0.8;
}

.forget-pass-input {
    position: relative;
    display: flex;
    flex-direction: column;
}

.forget-pass-input input {
    border: 1px solid black;
    border-radius: var(--radius-input);
    padding: 0.5em;
}

.forget-pass-input label {
    padding-top: 1em;
    color: var(--slate);
}

.forget-pass-container .toggle-password {
    position: absolute;
    right: 10px;
    font-size: 1.5em;
    top: 67%;
    transform: translateY(-50%);
    cursor: pointer;
}
.forget-pass-container .toggle-password-second{
    font-size: 1.5em;
    position: absolute;
    right: 10px;
    top: 67%;
    transform: translateY(-50%);
    cursor: pointer;
}
.forget-submit-btn {
    margin-top: 1em;
    color: var(--text-white);
    background-color: var(--orange);
    font-size: var(--s-font-size);
    border-radius: var(--radius);
    padding: 0.7em 1.5em;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.8;
}

.forget-submit-btn:hover {
    opacity: 1;
}

.forget-pass-container p>span {
    color: var(--text-black);
    font-weight: 900;
}

.forget-resend-btn {
    margin-top: 1em;
    color: var(--text-white);
    background-color: var(--orange);
    font-size: var(--s-font-size);
    border-radius: var(--radius);
    border: 1px solid var(--slate);
    padding: 0.7em 1.5em;
    font-weight: 600;
    cursor: pointer;
}

.forget-resend-btn:hover {
    border: 1px solid var(--bg-black);
    box-shadow: var(--container-shadow);
}

.forget-resend-btn:disabled {
    opacity: 0.7;
    cursor:not-allowed;
}

.forget-resend-btn:disabled:hover {
    border: 1px solid var(--slate);
    box-shadow: none;
}

.logo-forget-pass img {
    width: 80px;
    height: 60px;
    opacity: 0.6;
}

.logo-forget-pass a {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: var(--s-font-size);
    font-weight: 600;
    line-height: 25px;
    text-decoration: none;
    color: var(--gray);
}

.forget-pass-container > a {
    margin-top: 1em;
    font-size: var(--xs-font-size);
    font-weight: 600;
    line-height: 25px;
    text-decoration: none;
    color: var(--slate);
    text-shadow: var(--text-shadow);
    text-align: right;
}
.error{
    font-size: var(--xxs-font-size) !important;
}

.wait-message {
    margin: 0 auto;
}