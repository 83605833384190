/* header.css */

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1000;
  background-color: var(--bg-white);
  height: 80px;
  box-shadow: var(--text-shadow);
}

.after-header {
  width: 100%;
  height: 77px;
  background-image: var(--bg-line-gradient-hd);
  position: fixed;
  z-index: 98;
}

header::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  -webkit-backdrop-filter:blur(50px);
  backdrop-filter: blur(50px);
  z-index: -1;
}

header::after {
  content: "";
  top: 0;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.8s;
}

header.scrolled {
  /* background-color: var(--orange); */
}
.header-community-logo a{
  font-size: var(--s-font-size);
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;
  color: var(--green);    
  display: flex;
  align-items: center;
  justify-content: center;
}
header > a:nth-child(1) {
  font-size: var(--s-font-size);
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;
  color: var(--green);
  display: flex;
  align-items: center;
}

#check {
  display: none;
}

#check:checked ~ .icons #menu-icon {
  display: none;
}

#check:checked ~ .icons #close-icon {
  display: block;
}

.navbar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: transparent;
  -webkit-backdrop-filter:blur(50px);
  backdrop-filter: blur(50px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 99;
}

#check:checked ~ .navbar {
  height: auto;
  text-align: center;
  padding: 1em 0;
}

.nav-item {
  position: relative;
  color: var(--gray);
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  display: block;
  margin: var(--l-space) 0;
  text-align: center;
  transform: translateY(-50px);
  opacity: 0;
  transition: all 0.3s ease;
  cursor: pointer;
}

.navbar a {
  font-size: var(--xxs-font-size);
  font-weight: 600;
  text-transform: uppercase;
}

.nav-item:hover::after {
  width: auto;
}

#check:checked ~ .navbar a {
  transform: translateY(0);
  opacity: 1;
  transition-delay: calc(0.15s * var(--i));
}

.logo {
  width: 2em;
}

.img-container,
.dropdown-toggle {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  width: 46px;
  height: 46px;
  margin-left: 20px;
  overflow: hidden;
  border: 4px solid var(--orange)
  }

.profile-img {
  object-fit: cover;
  width: 70%;
  margin: 0 auto;
}

.dropdown {
  position: relative;
}

.dropdown-checkbox {
  display: none;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-self: center;
}
/* dropdown-menu */
.dropdown-menu {
  width: 100%;
  -webkit-backdrop-filter:blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  position: absolute;
  right: -100px;
  min-width: 17rem;
  padding: 0.5rem 0;
  margin: var(--s-space) 0 0;
  font-size: 1rem;
  color: #292b2c;
  text-align: left;
  list-style: none;
  background-color: var(--bg-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: var(--radius);
  flex-direction: column;
  align-items: center;
  top: 50px;
}

.dropdown-menu .social-icons-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.2rem;
  transform: translateX(-50%);
  background-color: var(--green);
  transition: all 0.5s ease;
  border-radius: 15px;
  font-size: 1rem;
  color: #292b2c;
  text-align: left;
  list-style: none;
}

.dropdown-menu.active {
  display: block;
}
.dropdown-menu .dropdown-item {
  display: flex;
  justify-content: center;
  max-width: 200px;
  font-size: var(--xs-font-size);
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292b2c;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  margin: 0.5em 0;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
}

.dropdown-item button {
  background-color: transparent;
  font-size: inherit;
}

.dropdown-checkbox:checked + .dropdown-toggle + .dropdown-menu {
  display: block;
}

.font-icons-header {
  display: none;
}

.social-icons-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--l-space);
  position: relative;

}
/* alert modal */
.alert-modal {
  position: relative;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.incomplete-profile {
  /* color: var(--red) !important;   */
  position: relative;
  display: inline-flex;
  align-items: center;
  /* font-weight: 900 !important;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.7); */
}
.warning-icon {
  position: absolute;
  background-color: var(--bg-white);
  color: var(--red) !important;
  border-radius: 50%;
  cursor: pointer;
  font-size: calc(var(--xs-font-size) - 2px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  top: 25%;
  transform: translateY(-50%);
  right: -20px;
  z-index: 1;
  animation: bounce 3s infinite;
}
.warning-icon-image {
  position: absolute;
  background-color: white;
  color: var(--red) !important;
  border-radius: 50%;
  cursor: pointer;
  font-size: var(--xs-font-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  top: 0;
  transform: translateY(-50%);
  right: -25px;
  z-index: 1;
  animation: bounce 4s infinite;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 300px;
  text-align: center;
}
/* desktop */
@media (min-width: 850px) {
  header.scrolled {
    background-color: var(--bg-white);
  }
  .desktop-unactive {
    display: none !important;
  }
  .social-mobile {
    display: none !important;
  }
  .social-icons-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--l-space);
    position: relative;
    
  }

  .social-icons-header:hover {
    background-color: var(--gravel);
    border-radius: var(--radius);
  }
  .navy {
    display: flex;
    align-items: center;
    gap: calc((160 / 50) * var(--xl-space));
  }

  header {
    justify-content: space-between;
    padding: 0 9%;
  }

  .navbar {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background: none;
    -webkit-backdrop-filter:none;
    backdrop-filter: none;
    box-shadow: none;
    overflow: initial;
    transition: none;
    display: flex;
    gap: 2em;
    margin-left: auto;
  }

  .navbar a {
    transform: none;
    opacity: 1;
    transition: none;
  }

  .nav-item {
    display: inline;
    color: var(--gray);
    font-weight: 600;
    line-height: 25px;
    /* letter-spacing: -0.13px; */
    text-decoration: none;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .nav-item:hover,
  .nav-item.active {
    color: var(--green);
  }

  .nav-item::after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    width: 0;
    height: 0.2rem;
    transform: translateX(-50%);
    background-color: var(--green);
    transition: all 0.5s ease;
    border-radius: 15px;
  }

  .nav-item:hover::after {
    width: 100%;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: -100px;
    /* left: auto; */
    min-width: 17rem;
    padding: 0.5rem 0;
    margin: var(--s-space) 0 0;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    list-style: none;
    background-color: var(--bg-white);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: var(--radius);
  }

  .dropdown-checkbox:checked + .dropdown-toggle + .dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50px;
  }
  .dropdown-menu .dropdown-item .desktop-unactive {
    display: none;
  }

  .dropdown-menu .dropdown-item {
    position: relative;
    font-size: var(--xs-font-size);
    cursor: pointer;
  }
  .dropdown-menu .social-icons-header::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0.2rem;
    transform: translateX(-50%);
    background-color: var(--green);
    transition: all 0.5s ease;
    border-radius: 15px;
  }
  .dropdown-menu .social-icons-header:hover::after {
    width: 90%;
  }
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:hover button {
    color: var(--green);
    cursor: pointer;
  }
  .warning-icon-image {
    color: var(--red);
  }
}
@media only screen and (max-width: 1200px) {
  .header.hide-on-mobile {
    /* display: none; */
  }

  .navbar {
    display: none;
  }
}

@media (max-width: 850px) {
  .dropdown-menu {
    right: -20px;
  }
}