.filters-map {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em 0;
}

.map {
  position: relative;
  display: flex;
  flex-direction: column;
}

.filter-main {
  margin: 1rem;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.logo-map img {
  width: 100%;
  max-width: calc(var(--container-min-width) - 90px);
  height: auto;
}

.filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.filter-main label:first-child {
  display: block;
  margin-bottom: var(--s-space);
  font-size: calc(var(--xs-font-size) + 2px);
  font-weight: bold;
  text-align: center;
  text-shadow: var(--text-shadow);
}

.filter-main label {
  display: block;
  font-size: calc(var(--xxs-font-size) + 2px);
  font-weight: bold;
  text-align: center;
  text-shadow: var(--text-shadow);
}

.option label {
  text-align: start;
  text-indent: 0;
}

.custom-select {
  position: relative;
  width: 100%;
}

.selected-option {
  padding: var(--s-space);
  font-size: var(--xs-font-size);
  border: 1px solid #ccc;
  border-radius: var(--radius-input);
  font-weight: 800;
  box-shadow: var(--text-shadow);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

/* .arrow {
  margin-left: var(--m-space);
  border: solid var(--bg-black);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.2s;
}

.arrow.open {
  transform: rotate(-135deg);
} */

.options-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 11%);
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.1);
  background-color: var(--bg-white);
  z-index: 500;
  width: 480px;
}

.options::-webkit-scrollbar {
  width: 10px;
}

.options::-webkit-scrollbar-track {
  background: transparent;
}

.options::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid #f1f1f1;
  border-radius: var(--radius-input);
}

.social-input {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.search-input {
  width: calc(100% - 1rem);
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  box-sizing: border-box;
  margin: 0.5rem;
}

.options {
  max-height: calc(var(--container-min-height) / 2);
  overflow-y: auto;
}

.option {
  display: flex;
  align-items: center;
  padding: var(--s-space);
  cursor: pointer;
  font-size: calc(var(--xs-font-size) + 2px);
}

.option input[type="checkbox"] {
  margin-right: 8px;
  height: 20px;

}

.option:hover {
  background-color: #f0f0f0;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: var(--m-font-size);
  position: fixed;
  top: 92px;
  right: 20px;
  z-index: 1005;
}

.hamburger-menu {
  position: relative;
  border-top-left-radius: calc(var(--radius-input) * 2);
  border-bottom-left-radius: calc(var(--radius-input) * 2);
  position: fixed;
  top: 74px;
  right: -100%;
  width: 85%;
  max-width: 400px;
  height: 91%;
  background: #fff;
  box-shadow: var(--container-shadow);
  transition: right 0.7s ease;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 20px 10px;
}

.hamburger-menu.open {
  right: 0;
}

.hamburger-menu .filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.filter-section {
  margin-bottom: 20px;
}

.hamburger-menu::-webkit-scrollbar {
  width: 10px;
}

.hamburger-menu::-webkit-scrollbar-track {
  background: transparent;
}

.hamburger-menu::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: 2px solid #f1f1f1;
  border-radius: var(--radius-input);
}

.filter-section h4 {
  font-size: calc(var(--xs-font-size) + 2px);
}

.filter-section label {
  font-size: var(--xs-font-size);
}

.clear-filters-hamburger {
  padding: var(--s-space);
  font-size: var(--xs-font-size);
  border: 1px solid #ccc;
  border-radius: var(--radius-input);
  font-weight: 800;
  box-shadow: var(--text-shadow);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  width: 100%;
  margin: 1rem 0;
}

@media (max-width: 850px) {
  .filters {
    display: none;
  }
  .hamburger {
    display: block;
  }
  .hamburger-menu {
    display: flex;
  }
}

@media (min-width: 850px) {
  .hamburger-menu {
    display: none;
  }
  .filter-main {
    max-width: 50%;
  }
  .selected-option {
    font-size: calc(var(--xs-font-size) + 2px);
  }
  .filters-map {
    align-items: start;
    flex-direction: row;
    gap: 7em;
  }
  .filters {
    flex-direction: row;
    flex: 0 0 65%;
    margin-right: 1em;

  }
  .logo-map img {
    width: 100%;
    max-width: calc(var(--container-min-width) - 40px);
    margin-top: 3em;
  }
  .custom-select .selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .clear-filters {
    padding: calc(var(--s-space) + 1px) 0;
    font-size: var(--xs-font-size);
    border: 1px solid #ccc;
    border-radius: var(--radius-input);
    font-weight: 800;
    box-shadow: var(--text-shadow);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    width: 50%;
    max-width: 350px;
    margin: 1rem auto;
    margin-top: 2.7em;
  }
  .clear-filters:hover {
    color: var(--red);
  }
  .custom-select .selected-option-text {
    flex: 1;
    text-align: center;
  }
}
@media (min-width: 767px ) and (max-width: 1200px){
  .logo-map img {
    margin-left: 5.5em;
  }
  .options-container {
    width: 390px;
  }
}
@media screen and (max-device-width: 1067px) and (orientation: landscape) {
  .selected-option {
    font-size: var(--xxs-font-size);
  }
  .clear-filters {
    font-size: var(--xxs-font-size);
    margin-top: 3.3em;
  }
  .options-container {
    width: 390px;
  }
}
@media (min-width: 1200px ){
  .option input[type="checkbox"] {
    margin-right: 8px;
    height: 23px;
    width: 22px;
  }
  .filter-main label {
 
    font-size: calc(var(--xs-font-size) + 2px);
  }
  .options-container {
    width: 480px;
  }
}
.map {
  position: relative;
  display: flex;
  flex-direction: column;
}
.option input[type="checkbox"] {
  margin-right: 8px;
  height: 23px;
  width: 22px;
}