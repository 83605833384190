.profile-section {
  background-image: url("../../../public/images/sign-up/background.jpg");
  padding: var(--xxxl-space);
  padding-top: 8em;
  display: flex;
  background-size: auto;
  position: relative;
  align-items: stretch;
}

.ads-submenu.show {
  max-height: 500px;
  opacity: 1;
}

.profile-section-edit,
.profile-section,
.profile-form,
.profile-data,
.avatar img {
  max-width: 100%;
  /* overflow-x: hidden; */
  box-sizing: border-box;
}

.profile-form span {
  color: var(--red);
}

.profile-form span.required-fields {
  text-align: center;
}

.account-menu-mobile.disabled, .account-menu.disabled{
  pointer-events: none;
  opacity: 0.3;
}

.main-profile {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-radius: var(--m-space);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  background-color: var(--bg-white);
}

.main-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 40px;
  gap: 40px;
  justify-content: flex-start;
}

.main-data > * {
  border-radius: var(--m-space);
  background-color: var(--bg-white);
}

.main-data  h3 {
  color: var(--green);
}

.menu-toggle {
  display: none;
  position: fixed;
  right: 2rem;
  background: none;
  border: none;
  font-size: 2rem;
  color: var(--orange);
  z-index: 10;
  cursor: pointer;
  right: calc(var(--s-space) - 0.2rem);
}

.account-menu {
  /* margin: 2rem 0; */
  position: sticky;
  /* top: 0; */
  /* width: 40%; */
  align-self: flex-start;
  height: 100%;
  padding-bottom: 40px;
}

.account-menu a,
.change-avatar-link {
  font-size: var(--xs-font-size);
  text-transform: uppercase;
  font-weight: 550;
  display: block;
  margin-top: 1rem;
  padding-left: 5vw;
  margin-bottom: 0.5rem;
  color: var(--orange);
  text-decoration: none;
  /* text-shadow: 0 2px 40px rgba(0, 0, 0, 0.09); */
}

.change-avatar-link {
  font-size: 1.2rem;
}

.account-menu a:hover {
  text-decoration: underline;
}

.account-menu a.active {
  color: var(--green-cm-light);

}

.account-menu a.active svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  color: var(--green-cm-light);
}

.account-menu-mobile a.active svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  color: var(--green-cm-light);
}
.account-menu .icon {
  padding-right: 1rem;
  width: 25px;
}

.profile-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: var(--l-space);
  margin: 2rem 0;
  width: 100%;
  position: relative;
  text-align: center;
}

.profile-data-btns {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.top-right-button {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 2;
  padding: var(--s-space);
  border-radius: var(--s-space);
  color: var(--orange);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.top-right-button:hover {
  background-color: var(--orange);
  color: #fff;
}

.top-right-button-profile-form {
  position: absolute;
  top: 5.3rem;
  right: 6.8rem;
  z-index: 2;
  padding: var(--s-space);
  border-radius: var(--s-space);
  color: var(--orange);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.top-right-button-profile-form:hover {
  background-color: var(--orange);
  color: #fff;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input {
  width: calc(100% - 30px);
  padding-right: 30px;
}

.password-input-container .toggle-password {
  font-size: 1.3em;
  position: absolute;
  top: 41%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: auto;
}

.admin-dashboard h3 {
  margin: initial;
  margin-top: 1em;
  margin-bottom: 1rem !important;
}

.btn-center {
  text-align: center;
  margin-top: 0;
}

.btn-center button.btn-orange {
  margin-top: 0;
  margin-bottom: 40px;
}

.unfinished-profiles {
  border-radius: var(--m-space);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  padding: var(--l-space);
}

.unfinished-profiles p:first-child {
  color: var(--toastify-color-error);
  font-weight: 650;
  margin-bottom: 18px;
  font-size: 20px;
}


.profile-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--m-space);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  padding: var(--xl-space) var(--xxxl-space);
  margin: 0 auto;
  /* position: relative; */
}

.profile-form label,
.user-data div {
  margin-bottom: 7px;
  font-weight: 550;
}

.user-data {
  margin-top: 1rem;
}

.user-data p,
.user-data h2 {
  margin-bottom: 0.8rem;
}

.user-data h2 {
  /* margin-bottom: 2rem; */
  font-size: var(--m-font-size);
  color: var(--green);
}

.user-data .icon {
  padding-right: 0.5rem;
}
.user-data input {
  width: 0;
}
.profile-section .gender {
  margin-bottom: -1rem;
}

.profile-section .gender,
.profile-section .date {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.profile-section .gender-options {
  display: flex;
  gap: 1rem;
}

.profile-section .date select {
  padding-right: 2px;
}

.profile-form input,
.profile-form select {
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: var(--m-space);
  margin-top: 0.5rem;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.profile-form .gender input,
.profile-form input[type="checkbox"] {
  box-shadow: none;
  transform: scale(1.5);
  accent-color: var(--orange);
}

.profile-form input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.btn-profile {
  width: 200px;
  align-self: center;
}

.profile-section h3 {
  text-align: center;
  margin-bottom: 2rem;
}


.announced .ads,
.pending .ads,
.view-more {
  transition: transform 2s ease;
}

.ads:hover {
  transform: scale(1.05);
}

.announced .ads {
  background-color: #385a41;
}

.announced .user-data p,
.announced .user-data h3 {
  color: #fff;
}

.announced .user-data h3 {
  margin-bottom: 0.5rem;
  font-size: calc(var(--s-font-size) - 2px);
  padding-top: 0.6em;
  /* text-shadow: var(--text-shadow); */
}

.announced .user-data-ads .ads-elipse,
.announced .user-data-ads .ads-data-elipse {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.announced .user-data-ads .elipse {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 28px;
  background-color: #d9d9d9;
  border-radius: 80px;
  color: #1f372a;
  margin-bottom: 0.5rem;
}

.announced .user-data-ads .elipse.price {
  background-color: #1f372a;
  color: #fff;
  font-size: x-large;
}

.ads-btns {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.ads-btn:hover,
.view-more:hover {
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  transform: scale(1.05);
}

.ads-btn {
  padding: 17px 27px;
  border-radius: 28px;
  color: #fff;
  font-size: var(--xs-font-size);
  cursor: pointer;
  opacity: 1;
  position: relative;
  z-index: 8;
  pointer-events: auto;
}

.ads-btns .red {
  background-color: #933d24;
}

.ads-btns .green {
  background-color: #1f372a;
}

.avatar-announced p {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  display: inline;
  color: var(--text-white);
  font-size: calc(var(--xs-font-size) + 2px);
  border: 2px solid var(--yellow);
  border-radius: 16px;
  padding: 0.1em 0.5em;
  padding-bottom: 0.3em;
  top: 10px;
  left: 12%;
  background-color: var(--green-text);
}

.announced {
  position: relative;
  margin-top: 50px;
}

.announced .ads-card {
  width: 90%;
  margin:  30px auto;
padding-bottom: 40px;
}

/* .announced::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--border-color, #385a41);
  margin: 1rem 0;
  border-radius: 50%;
} */

.announced .view-more,
.announced .pending-approval,
.announced .denied {
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  display: inline;
  color: #1f372a;
  font-size: calc(var(--xxxs-font-size) + 2px);
  border: 2px solid #1f372a;
  border-radius: 20px;
  padding: 0.6em 0.8em;
  /* padding-bottom: 0.3em; */
  top: -20px;
  right: 8%;
  background-color: var(--bg-white);
  z-index: 6;
  font-weight: bold;

}

.announced .view-more {
  cursor: pointer;
}

.announced .pending-approval {
  color: #1f372a;
  opacity: 1;
}

.announced.pending {
  opacity: 0.6;
  position: relative;
}

.announced.denied {
  opacity: 0.6;
  filter: grayscale(60%);
}

.announced .denied {
  opacity: 0.6;
}

.announced .admin-comment {
  text-align: left;
  color: #1f372a;
  font-size: calc(var(--xs-font-size) + 2px);
  border: 2px solid #1f372a;
  border-radius: 16px;
  padding: 0.3em 0.5em;
  margin-top: 1rem;
  background-color: var(--bg-white);
  font-weight: bold;
  width: 100%;
}

.announced .refresh {
  text-align: left;
  color: var(--orange);
  font-size: calc(var(--xs-font-size) + 2px);
  border: 2px solid #1f372a;
  border-radius: 16px;
  padding: 0.3em 0.5em;
  margin-top: 1rem;
  background-color: var(--bg-white);
  width: 100%;
}

.announced .refresh span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-width: 20%;
}

.avatar img {
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  width: 140px;
  /* height: 100px; */
  border-radius: 50%;
  object-fit: fill;
  border: 6px solid var(--orange);
  padding: 10px;
}
.profile-data .avatar {
  /* border: 6px solid var(--orange); */
  padding: 12px;
  border-radius: 50%;
}
.profile-form .avatar img {
  height: 140px;
}
.avatar-announced {
  width: 100%;
}

.avatar-announced img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: var(--s-space);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.announced .user-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0;
}

.no-ads {
  text-align: center;
  font-size: 1.4rem;
  color: var(--green);
  padding: 40px 0;
}

.avatar-announced img {
  width: 100%;
  height: 170px;
  border-radius: var(--s-space);
  object-fit: cover;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.profile-section-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem 0; */
  width: 100%;
}

.profile-section-edit .user-data {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.profile-section-edit .user-data > div {
  margin-bottom: 15px;
}

.profile-section-edit .btn-inline,
.profile-form .btn-inline {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.profile-section-edit button {
  cursor: pointer;
}

.profile-data .warning-icon-image {
  position: static;
}
.warning-info {
  display: inline-block;
  margin-right: 10px;
}

.error {
  color: var(--red);
  font-size: var(--xxs-font-size);
  padding-left: 0.5rem;
}

.label-image {
  letter-spacing: 0.05rem;
  font-size: var(--xs-font-size);
  padding: 0.6rem 1rem;
  margin-top: 1rem;
  border-radius: 10px;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  background-color: var(--orange);
  color: var(--bg-white);
  /* &:hover {
  } */
}

.admin-dashboard {
  position: relative;
  padding-bottom: 3em;
}

.ads-submenu {
  margin-left: 20px;
  border-left: 2px solid #ddd;
  padding-left: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.1s ease-out, opacity 1.1s ease-out;
  opacity: 0;
}

.ads-submenu.show {
  max-height: 500px;
  opacity: 1;
}

.ads-submenu a {
  display: block;
  position: relative;
}

.ads-submenu a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 4px;
  height: 100%;
  background-color: #aaa;
}

.ads-submenu a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 12px;
  height: 2px;
  background-color: #aaa;
}

input.input-image {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* @media (min-width: 850px) {
  .warning-icon-image {
    color: var(--red);
  }
}

@media (min-width: 850px) and (orientation: landscape) {
  .profile-form {
    width: 60%;
    margin: auto;
  }

  .profile-section {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-form #email,
  .profile-form #name {
    width: 100%;
  }

  .profile-data {
    flex-direction: row;
    align-items: flex-start;
  }
} */

/* @media (max-width: 440px) {
  .profile-form .btn-inline {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .profile-section .gender,
  .profile-section .date {
    flex-direction: column;
  }
  .password-input-container input {
    font-size: 12px;
  }
  .input {
    font-size: 12px;
  }
} */

/* @media (min-width: 650px) {
  .profile-data {
    border-radius: var(--m-space);
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
    padding: var(--l-space);
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .avatar img {
    width: 150px;
    height: 150px;
  }

  .announced .view-more,
  .announced .pending-approval {
    top: -18px;
  }
} */

/* @media (min-width: 850px) and (orientation: landscape) {
  .profile-section-edit #email,
  .profile-section-edit #name {
    width: 100%;
  }

  .profile-section #email {
    padding: var(--s-space);
  }

  .profile-data {
    flex-direction: column;
  }

  .avatar-announced,
  .announced .user-data {
    width: 50%;
  }

  .ads-elipse {
    justify-content: flex-start;
  }

  .avatar-announced p {
    top: 55px;
  }
} */

/* @media (max-width: 600px) {
  .profile-data .user-data h2 {
    font-size: var(--m-font-size);
  }
} */

/* @media (min-width: 600px) and (orientation: landscape) {
  .profile-form {
    width: 80%;
    margin: auto;
  }

  .profile-section .gender-options {
    gap: 3rem;
  }

  .menu-toggle {
    right: calc(var(--s-space) + 2.8rem);
  }
} */

/* @media (min-width: 1050px) and (orientation: landscape) {
  .avatar-announced p {
    top: 28px;
  }

  .profile-section {
    flex-direction: row;
    align-items: flex-start;
  }

  .ads-submenu.show {
    max-height: 500px;
    opacity: 1;
  }
} */

/* @media (max-width: 970px) {
  .menu-toggle {
    display: block;
    top: 6rem;
    right: calc(var(--s-space) + 2rem);
  }

  .account-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100%;
    background: #fff;
    transition: right 0.3s ease;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 9;
    overflow-y: auto;
    transition: all 0.9s ease-in;
  }

  .account-menu.open {
    right: 0;
  }
} */

/* @media (min-width: 970px) and (max-width: 1000px) {
  .profile-section {
    flex-direction: row;
    align-items: flex-start;
  }

  .account-menu {
    height: 100vh;
    position: sticky;
    top: 8em;
    width: 20%;
    margin-right: 2rem;
    margin-left: 2rem;
    box-shadow: 2px 0 0 rgba(0, 0, 0, 0.1);
  }

  .account-menu a {
    display: block;
  }

  .main-profile {
    flex-direction: column;
    width: 75%;
    position: relative;
    background-color: var(--bg-white);
  }
} */
/* @media (min-width: 768px) and (max-width: 1200px) {
  .top-right-button-profile-form {
    position: absolute;
    top: 5.3rem;
    right: 11.8rem;
    z-index: 2;
    padding: var(--s-space);
    border-radius: var(--s-space);
    color: var(--orange);
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  }

  .profile-section {
    flex-direction: row;
    align-items: stretch;
    left: 30px;
  }
} */

/* @media (min-width: 990px) and (max-width: 1200px) {
  .profile-section {
    left: 30px;
  }
} */

/* @media (min-width: 1200px) {
  .top-right-button-profile-form {
    position: absolute;
    top: 5.3rem;
    right: 20.8rem;
    z-index: 2;
    padding: var(--s-space);
    border-radius: var(--s-space);
    color: var(--orange);
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
  }
  .profile-section {
    flex-direction: row;
    align-items: stretch;
  }
} */
.account-menu-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .main-profile {
    border-radius: 0px;
  }

  .profile-section {
    padding: 0;
    padding-top: 4em;
    flex-direction: column;
  }

  .main-profile {
    width: 100%;
  }

  .main-data {
    padding-left: 0;
    gap: 0px;
    flex-direction: column;
    
    /* align-items: stretch; */
  }

  .main-data > * {
    border-radius: 0px;
  }
  .main-data .profile-data-btns {
    flex-direction: column;
    gap: 10px;
  }

  .main-data .profile-data-btns button {
    width: 100%;
  }

  .profile-form {
    padding: var(--xl-space);
    border-radius: 0px;
  }

  .profile-form .btn-inline {
    gap: 0.1rem;
    flex-direction: column;
  }

  .profile-data {
    margin: 0px;
    margin-top: 20px;
    flex-direction: row;
  }

  .menu-toggle {
    display: block;
    top: 6rem;
    right: calc(var(--s-space) + 2rem);
  }

  .account-menu {
    display: none;
  }

  .account-menu-mobile {
    width: 100%;
    background-color: var(--bg-gray);;
    z-index: 9;
    display: flex;
    justify-content: space-evenly;
    padding-top: 6px;
  }

  .account-menu-mobile.open {
    right: 0;
  }
  .account-menu-mobile a {
    display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px 22px;
  padding-bottom: 16px;
  font-size: 22px;
  text-align: center;
  position: relative;
  }

  .account-menu-mobile a svg {
    color: #385a41;
  }

  .account-menu-mobile a.active {
    background-color: var(--bg-white);
    color: var(--green);
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
  .user-data {
    text-align: start;
    text-indent: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .profile-section {
    margin-left: 60px;
  }
  .main-data .profile-data-btns {
    flex-direction: row;
    justify-content: space-evenly ;
  }
}

@media (min-width: 1024px) {
  .profile-section {
    position:inherit;
  }
  .main-profile {
    position: sticky;
    top: 50px;
    align-self: flex-start;
  }

  .announced-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
  }

  .announced {
    width: 370px;
    margin-top: 20px;
  }

  button.ads-btn {
    padding: 12px 18px;
  }

  .announced .view-more, .announced .pending-approval, .announced .denied {
    top: 10px;
  }

  .announced .ads-info h3 {
    text-align: start;
  }
}