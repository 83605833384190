.where-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.where-modal-content {
    position: absolute;
    top: 50px;
    background: var(--grey-bg);
    padding: 2rem;
    border-radius: 10px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.where-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.where-modal-content h2 {
    margin-top: 0;
    font-size: calc(var(--m-font-size) + 5px);
}

.where-select-container {
    position: relative;


}

.where-select {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: var(--xs-font-size);

    overflow-y: auto;
}

.where-search-button {
    padding: 0.5rem 1rem;
    border: none;
    background: var(--yellow);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--xs-font-size);
    display: block;
    width: 100%;
}

.where-modal-content .btn-general{
    width: 100%;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .where-search-button {
        font-size: var(--s-font-size);
    }
}

@media (min-width: 1200px) {
    .where-search-button {
        font-size: var(--m-font-size);
    }

    .where-modal-content {
        position: relative;


    }

    .where-select {
        font-size: var(--xs-font-size);
    }
}