.motto-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    justify-content: center;
    padding: 40px 180px 0 180px;
    gap: 40px;
  }


  @media (max-width: 900px) {
    .motto-section {
        grid-template-columns: repeat(1, 1fr);
        padding: 60px 40px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {

    .motto-section {
        padding: 4em 2em;
        padding-left: 12%;
        gap: 20px;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    .motto-section {
        padding: 20px 40px;
    }
  }