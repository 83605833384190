.delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delete-modal-content {
    background: var(--grey-bg);
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    width: 360px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.delete-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.ad-details{
    margin: 0;
    margin-bottom: 1rem;
   text-align: center;
}

.ad-details .ad-image {
    width: 95%;
    border-radius: 10px;
}

.ads-btn {
    padding: 17px 27px;
    border-radius: 24px;
    color: #fff;
    font-size: var(--xs-font-size);
    cursor: pointer;
    text-align: center;
    justify-content: center;
}

.ads-btns .red {
    background-color: #933D24;
}

.ads-btns .green {
    background-color: #1F372A;
}

.delete-modal-content h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
}

@media (min-width: 768px) {
    .delete-modal-content{
        width: 400px;
    }
}
