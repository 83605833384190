.footer-privacy-commun {
    display: none;
}

.footer-com-fix{ 
    position:sticky;
    bottom: 0;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .footer-privacy-commun {
        position: sticky;
        bottom: 0;
    }
    .footer-privacy-commun {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-black);
        color: var(--text-white);
        align-items: center;
        filter: contrast(50%);

        opacity: 1;

    }

    .footer-privacy-commun p {
        font-size: var(--xs-font-size);
        padding: var(--s-space) 0;
        color: var(--text-white);
    }
}
@media (min-width: 1200px) {
    footer{
        position:relative;
       
    }
}