.ad-community-background {
    /* background-image: var(--bg-image); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.create-ad-main {
    margin: 0 auto;
    padding: 1em 1em;
    min-height: 100vh;
    /* font-family: "Inter", sans-serif; */
}

.error {
	color: var(--red);
	/* visibility: hidden; */
    text-indent: initial;
    padding-top: 0.5em;
}
.ad-card-create {
    margin-top: 6em;
    display: flex;
    /* background-color: var(--create-form-bg); */
    /* border: 4px solid var(--brown); */
    /* border-radius: calc(var(--radius) + 28px); */
    flex-direction: column;
    padding: 0.5em 1em;
}

.ad-card-create h2 {
    font-size: var(--m-font-size);
    color: var(--orange);
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    padding-bottom: 1em;
    /* text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25); */
}

.ad-create-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.ad-info-desc {
    display: flex;
    flex-direction: column;
}
    /* .desc-sub-text {
        font-size: 8px !important;
        padding-top:0.6em ;
        line-height: 8.5px;
        color: var(--brown);
        font-weight: 600;
    } */

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1em;
}

.form-group label {
    padding-bottom: 0.5em;
    font-size: calc(var(--xxs-font-size) + 1px);
    font-weight: 550;
    /* color: var(--brown); */
}

.additional-fields {
    display: flex;
    flex-direction: column;
}

.additional-fields .email-create {
    display: flex;
    width: 100%;
}

.additional-fields .email-create input {

    width: 100% !important;
}

.additional-fields-names {
    display: flex;
    flex-direction: column;
}

.additional-fields-price {
    display: flex;
  flex-direction: column;
  text-align: justify;
}
.form-group input,
.form-group textarea,
.form-group select {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: var(--m-space);
    /* margin-top: 0.5rem; */
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
}

.form-group input:focus, .form-group textarea:focus,
.form-group select:focus {
    outline: 2px solid var(--orange);
}

.form-group input,
.form-group select {
    opacity: 1;
    color: #111;
    /* height: 55px; */
    /* padding: 0 1em; */
    font-size: calc(var(--xxs-font-size) + 1px);

}
.react-datepicker__input-container {
    display: flex;
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
}
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* remove arrows in  Firefox */
/* input[type="number"] {
    -moz-appearance: textfield;
} */

.form-group textarea {
    height: 111px;
    padding: 1em;
    font-size: calc(var(--xxs-font-size) + 1px);
    accent-color: rgb(52, 45, 32);
}

.ad-address {
    display: flex;
    flex-direction: column;
}

.checkbox-group {
    padding-bottom: 1em;
    display: flex;
    align-items: center;
}

.checkbox-group input {
    background-color: #fff;
    /* border: 2px solid var(--brown); */
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
    transform: scale(2) translateX(4px);
    accent-color: var(--orange)
}

.checkbox-group label {
    font-size: calc(var(--xxs-font-size) - 4px);
    padding-left: 12px;
}

.image-upload-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
    justify-items: center;
    
}

.image-upload {
    /* border: 1px dashed #ccc; */
    /* padding: 10px; */
    display: flex;
    text-align: center;
    position: relative;
    border: 4px solid var(--green);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
    overflow: hidden;
}

.image-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

}
.image-upload svg {
    width: 50%;
    height: 50%;
}
.image-upload img {
    /* max-width: 98px;
    height: 98px;
    object-fit: cover;
    border-radius: calc(var(--radius-input) + 6px);
     */
     width: 100%;
     height: 100%;
     object-fit: cover;
}

.plus-icon-ad {
    font-size: 24px;
    color: var(--bg-white) !important;
    background-color: var(--orange);
    padding: 0.5em;
    border-radius: 50%;
}

.button-group {
    display: flex;
    gap: 1em;
    justify-content: center;
    padding: 1em 0;
    flex-wrap: wrap;
}

/* .button-group button {
    font-size: calc(var(--xxs-font-size) + 1px);
    font-weight: 600;
    border-radius: var(--radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    cursor: pointer;

} */

/* .button-group button[type="submit"] {
    color: var(--bg-white);
    padding: 1em 0.8em;
    background-color: var(--button-bg);
} */

/* .button-group button[type="button"] {
    color: var(--button-bg);
    padding: 1em 1.8em;
    border: 4px solid var(--button-bg);
} */
.remove-image{
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.remove-image:hover {
    border: 2px solid var(--brown);
    background-color: rgba(255, 0, 0, 0.8);
}
@media (min-width: 768px) and (max-width: 1199px) {
    .ad-card-create {
        max-width: 980px;
        margin: 6em auto 2em 8em;
        padding: 0.5em 2em
    }
    .ad-regions{
        display: flex;
        flex-direction: row;
        gap: 0.8em
    }
    .react-datepicker__input-container {
        display: flex;
        width: 100%;
        gap: 1em;
    }
    .additional-fields-price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: justify;
        gap: 1em;
    }
    .additional-fields .email-create input {

        width: 100% !important;
    }
    .desc-sub-text {
        font-size: 14px !important;
        padding-top:0.6em ;
        line-height: 8.5px;
        color: var(--brown);
        font-weight: 600;
    }
    .form-group label {
        font-size: calc(var(--xs-font-size) + 1px);
        padding-top: 1.5em;
    }

    .ad-info-desc {
        flex-direction: row;
        gap: 1em;
    }

    .additional-fields-names {
        display: flex;
        flex-direction: row;
        gap: 1em;
    }

    .form-group textarea {
        height: 136px;
        font-size: calc(var(--xxs-font-size) + 1px);

    }

    .address-check {
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex: 1;
    }

    .image-upload-container {
        display: flex;
        justify-content: space-around;
    }

    .image-upload-container {
        display: flex;
        justify-content: space-around;
    }

    .ad-address {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
    }

    .ad-card-create h2 {
        padding-top: 1em;
        font-size: calc(var(--m-font-size) + 8px);
    }

    .form-group select,
    .form-group input {
        font-size: calc(var(--xxs-font-size));
        height: 64px;
    }

    .checkbox-group label {
        font-size: calc(var(--xs-font-size));
    }


}

@media (min-width: 1200px) {
    .form-group label {
        font-size: calc(var(--s-font-size) + 1px);
        padding-top: 1.5em;
    }
    .desc-sub-text {
        font-size: 18px !important;
        padding-top:0.6em ;
        line-height: 8.5px;
        color: var(--brown);
        font-weight: 600;
    }
    .additional-fields-price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: justify;
        gap: 1em;
    }
    .react-datepicker__input-container {
        display: flex;
        width: 100%;
       
    }
    .additional-fields-names {
        display: flex;
        flex-direction: row;
        gap: 1em;
    }

    .additional-fields .email-create input {

        width: 100% !important;
    }

    .ad-card-create {
        width: 1280px;
        margin: 6em auto 0 auto;
        padding: 0.5em 8em;
    }

    .ad-info-desc {
        flex-direction: row;
        gap: 1em;
    }

    .form-group textarea {
        height: 136px;
        font-size: calc(var(--xs-font-size) + 1px);

    }
    .ad-regions{
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
    .address-check {
        display: flex;
        flex-direction: column;;
        gap: 1em;
        flex: 1;
    }

    .image-upload-container {
        display: flex;
        justify-content: space-around;
    }

    .ad-address {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
    }

    .image-upload {
        width: 175px;
        height: 175px;
    }

    .ad-card-create h2 {
        padding-top: 1em;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .checkbox-group label {
        font-size: calc(var(--xs-font-size));
    }

    .checkbox-group input {
        width: 20px;
        height: 20px;
        background-color: #fff;
        /* border: 2px solid var(--brown); */
        border-radius: 3px;
        position: relative;
        cursor: pointer;
    }

    .form-group select,
    .form-group input {
        font-size: calc(var(--xs-font-size));
        height: 64px;
    }
    .image-upload input{
        height: 175px;

    }
    .image-upload img {
        max-width: 173px;
        height: 173px;
    }

    .button-group {
        padding: 3em 0;
        gap: 4em;
    }

    .button-group button {
        font-size: calc(var(--s-font-size) + 1px);
        font-weight: 600;
        border-radius: var(--radius);
    }

    .button-group button[type="submit"] {
        color: var(--bg-white);
        padding: 1em 1.8em;
        background-color: var(--button-bg);
    }

    .button-group button[type="button"] {
        color: var(--button-bg);
        padding: 1em 2.8em;
        border: 4px solid var(--button-bg);
    }
    .remove-image {
        top: 24px;
        right: 24px;
        width: 34px;
        height: 34px;
    }
}