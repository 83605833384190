.image-modal-overlay-ads{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease-in-out;
}

.image-modal-content-ads {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 600px;
    text-align: center;
    animation: slideIn 0.3s ease-in-out;
}

.image-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.login-prompt {
    padding: 20px;
    color: var( --text-black);
    font-weight: 700;
}
.login-prompt p {
    font-size: var(--xs-font-size);  

}
.login-prompt a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.login-prompt a:hover {
    text-decoration: underline;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}
@media (min-width: 1200px) {
    .login-prompt p {
        font-size: var(--m-font-size);  
    
    }
}