.ad-description-editor {
    font-size: var(--xxs-font-size);
}

.ad-sidebar-map-details {
    position: fixed;
    background-image: url('../../../../../public/images/sign-up/background.jpg');
    left: 0; 
    top: 60%;
    width: 300px;
    max-width: 100%;
    height: 80vh; 
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    overflow-x: hidden; 
    z-index: 9999;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1.2px solid rgba(0, 0, 0, 0.2);
    transition: transform 1.2s ease-in-out;
    box-sizing: border-box; 
}

.ad-close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    transition: opacity 1s ease-in-out;
}

.ad-close-button {
    cursor: pointer;
    position: absolute;
    padding: 5px;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    opacity: 0;
    pointer-events: all;
}

.active-sidebar .ad-close-button {
    pointer-events: all;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.ad-sidebar-content {
    width: 100%;
    opacity: 0;
    pointer-events: all;
    margin-bottom: 15px;
    transition: opacity 2s;
    overflow: hidden;
}

.active-sidebar .ad-sidebar-content {
    opacity: 1;
    pointer-events: visible;
    transition: opacity 2s;
}

.ad-sidebar-content h2 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    padding: 1em 0;
    background-color: var(--orange);
    white-space: nowrap;
    height: 25px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ad-user-map-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ad-user-map-info p {
    font-size: 14px;
}

.ad-user-map-img {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ad-map-desc-user {
    text-indent: initial;
    gap: 0.5em;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 10px;
    width: 100%;
    box-sizing: border-box; 
}

.ad-map {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 20px;
    border-radius: var(--radius);
    background-color: var(--gravel);
    position: relative;
    box-shadow: var(--container-shadow);
    width: 95%;
    box-sizing: border-box; 
}

.ad-title {
    padding: 2px 0;
    text-align: center;
    text-shadow: var(--text-shadow);
    color: var(--grey);
    font-weight: 300;
    width: 100%;
    box-sizing: border-box; 
}

.ad-scroll {
    flex-direction: column;
    height: 60%;
    min-height: 200px;
    z-index: 99999;
    transition: height 2s ease;
}

.ad-scroll h3 {
    text-align: center;
    margin: 35% auto;
    text-shadow: var(--text-shadow);
    color: var(--gray);
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
}

.ad-scroll-side-content {
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 300px); 
    box-sizing: border-box; 
}

.ad-scroll-side-content::-webkit-scrollbar {
    width: 10px;
}

.ad-scroll-side-content::-webkit-scrollbar-track {
    background: transparent;
}

.ad-scroll-side-content::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border-radius: var(--radius-input);
    border: none; 
}

.ad-desc {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1.5em auto;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.ad-map img {
    width: 160px;
    height: 160px;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    object-fit: cover;
    overflow: hidden;
    flex: 0 0 35%;
    cursor: pointer;
}

.ad-map h3 {
    margin: 0;
    font-size: var(--s-font-size);
    padding-bottom: 0.2em;
    width: 100%;
    box-sizing: border-box;
}

.ad-map-valid {
    padding-bottom: 0.4em;
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-shadow: var(--text-shadow);
    font-weight: 200;
}

.ad-map p {
    margin: 5px 0 0;
    font-size: 1em;
}

.ad-desc-map {
}

.ad-category {
    position: absolute;
    border-radius: var(--radius-input);
    right: 10px;
    top: -10%;
    background-color: var(--orange);
    padding: 0.5em;
    text-indent: initial;
    color: white;
    width: 110px;
    text-align: center;
    box-shadow: var(--container-shadow);
    box-sizing: border-box; 
}

.active-sidebar .ad-sidebar-map-details {
    max-width: 680px;
    width: 100%;
    transition: width 4s;
}

.ad-color-lines {
    width: 100%;
    height: 5px;
    background: linear-gradient(304deg, #1B6D4D, #E26020 22%, #BB1D3D 93%);
}

.ad-color-lines-pipe {
    width: 100%;
    height: 4px;
    background: linear-gradient(325deg, #ffffff 0%, #777777 50%, #ffffff 100%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.32);
}
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--orange);
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 24px;
  }
  .arrow-up-side-bar{
    color: var(--text-white)!important;
  }
  .scroll-to-top-button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  }
  .group-icon{
    cursor: pointer;
  }
  .group-icon.share-button-ad {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; 
  }
  
  .share-options {
    display: flex;
    flex-direction: row; 
    gap: 10px;
    position: absolute; 
    top: 100%; 
    left: 50%; 
    transform: translateX(-50%); 
    background-color: var(--green-second); 
    padding: 10px;
    z-index: 1000; 
  }
  
@media (min-width: 600px) {
    .ad-scroll {
        height: 70%;
    }

    .ad-user-map-info {
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: 850px) {
    .ad-user-map-info {
        flex-direction: row;
    }

    .ad-user-map-info p {
        font-size: 16px;
    }

    .ad-user-map-img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .color-lines {
        width: 100%;
        height: 8px;
        background: linear-gradient(304deg, #1B6D4D, #E26020 22%, #BB1D3D 93%);
    }

    .active-sidebar .ad-sidebar-map-details {
        max-width: 680px;
        width: 100%;
        transition: width 4s;
    }

    .ad-scroll {
        height: 35vh;
    }

    .ad-desc-user {
        flex: 0 0 65%;
        padding: initial;
    }

    .ad-map-desc-user {
        margin: auto;
    }
}
