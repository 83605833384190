.banner-section {
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url("../../../public/images/sign-up/selfie-seniors2.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: var(--xxl-space) 0;

}

/* .login-register-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: var(--m-space);
	box-sizing: border-box;
} */

.form__title {
	text-transform: uppercase;
	color: var(--orange);
	margin: var(--l-space) var(--m-space) var(--m-space) var(--m-space);
}

.link {
	color: var(--gray);
	margin: var(--m-space) 0;
	text-decoration: none;

	font-size: var(--xs-font-size);
}

.link-hidden span {
	color: var(--orange);
}

.error {
	color: var(--red);
}

.container {
	background-image: var(--bg-image);
	border-radius: var(--radius);
	box-shadow: var(--container-shadow);
	height: var(--container-height);
	min-height: var(--container-min-height);
	max-width: var(--container-max-width);
	overflow: hidden;
	position: relative;
	width: var(--container-width);
	opacity: 0.8;
}

.container__form {
	height: 100%;
	position: absolute;
	top: 0;
	transition: all 0.6s ease-in-out;
	width: 100%;
}

.container--signin {
	left: 0;
	width: 100%;
	z-index: 2;
}

.container--signup {
	left: 0;
	width: 100%;
	opacity: 0;
	z-index: 1;
	visibility: hidden;
}

.container.right-panel-active .container--signup {
	animation: show 0.6s;
	opacity: 1;
	visibility: visible;
}

.container.right-panel-active .container--signin {
	opacity: 0;
	visibility: hidden;
}

.container__overlay {
	display: none;
}

.btn:focus {
	outline: none;
}

.form {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 var(--xl-space);
	height: 100%;
	text-align: center;
}

.input {
	background-color: var(--bg-white);
	padding: 0.9rem 0.9rem;
	margin: 0.5rem 0 1rem 0;
	width: 100%;
	border: 0.3px solid #ccc;
	border-radius: 10px;

}

.input {
	background-color: var(--bg-white);
	padding: 0.9rem 0.9rem;
	margin: 0.5rem 0 1rem 0;
	width: 100%;
	border: 0.3px solid #ccc;
	border-radius: 10px;

}

.password-input-container {
	position: relative;
	width: 100%;
}

.password-input-container .input {
	width: calc(100% - 0px);
	display: inline-block;
}

.toggle-password {
	font-size: 1.3em;
	position: absolute;
	top: 41%;
	right: 5px;
	transform: translateY(-50%);
	cursor: pointer;
	pointer-events: auto;
}

@keyframes show {

	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

@media (max-width: 600px) {
    .form .form__title {
        font-size: var(--m-font-size);
    }
}

@media (min-width: 850px) {
	.container {
		max-width: 1000px;
		background-image: var(--bg-image);
	}

	.container__form {
		width: 50%;
	}

	.container--signin {
		width: 50%;
	}

	.container--signup {
		width: 50%;
		opacity: 0;
		visibility: hidden;
	}

	.container.right-panel-active .container--signin {
		transform: translateX(100%);
	}

	.container.right-panel-active .container--signup {
		transform: translateX(100%);
		opacity: 1;
		visibility: visible;
	}

	.container__overlay {
		display: block;
		height: 100%;
		left: 50%;
		overflow: hidden;
		position: absolute;
		top: 0;
		transition: transform 0.6s ease-in-out;
		width: 50%;
		z-index: 100;
	}

	.container.right-panel-active .container__overlay {
		transform: translateX(-100%);
	}

	.container__overlay .overlay1 {
		background-image: var(--bg-gradient);
		height: 100%;
		left: -100%;
		position: relative;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
		width: 200%;
	}

	.container.right-panel-active .overlay1 {
		transform: translateX(50%);
	}

	.overlay__panel {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: absolute;
		text-align: center;
		top: 0;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
		width: 50%;
	}

	.overlay--left {
		transform: translateX(-20%);
	}

	.container.right-panel-active .overlay--left {
		transform: translateX(0);
	}

	.overlay--right {
		right: 0;
		transform: translateX(0);
	}

	.container.right-panel-active .overlay--right {
		transform: translateX(20%);
	}

	.link-hidden {
		visibility: hidden;
	}

	.form {
		background-color: var(--bg-white);
	}
}

.label {
	color: var(--gray);
	letter-spacing: 0.05rem;
	text-align: left;
	align-self: flex-start;
	text-indent: 0.3rem;
}

.recaptcha-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	font-size: 12px;
}
.recaptcha-container .rc-anchor-checkbox-label {
	font-size: 2px;

}
@media (max-width: 750px) {
	.form{
		height: 80%;
		margin-top: 65px;
	}
	.container{
		width: 100%;
		height:100vh;
	}
	.password-input-container input{
		font-size: 12px;
	  }
	  .input{
		font-size: 12px;
	  }

	  .container-wrapper {
		padding: 0;
	  }
	  .banner-section {
		padding-top: 20px;
	  }
  }
