.bg-container {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../../../public/images/errorPage/broken-tv-screen.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  margin: auto;

  position: relative;
  text-align: center;
  overflow: hidden;
  width: 80%;
  max-width: var(--container-max-width);
  min-height: var(--container-min-height);
  height: var(--container-height);
  background-color: var(--bg-white);
  opacity: 0.8;

  box-shadow: var(--container-shadow);

  border-radius: var(--radius);
}

.not-found > h1 {
  font-size: var(--l-font-size);
  color: var(--red);
  margin: 0 var(--l-space);
}

.not-found > h2 {
  font-size: var(--m-font-size);
  color: var(--text-black);
  display: inline-block;
  margin: var(--m-space);
}

.menu-list {
  list-style-type: none;
  background: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--m-space);;
}

@media (min-width: 850px) {
  

  .not-found > h1 {
    font-size: var(--xl-font-size);
    
  }
  
  .not-found > h2 {
    font-size: var(--l-font-size);
    
  }

  .menu-list {
    list-style-type: none;
    background: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: var(--l-space);
  }
}
