:root{
  /* Colors */
  --bg-white: #fff;
  --bg-black: #000;
  --bg-gray: #f4f4f4;
  --bg-in-gray: #dad7d7;
  --bg-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../public/images/homePage/orange-pattern.jpg');
  --bg-image-rotated: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../public/images/homePage/orange-pattern.jpg');
  --button-bg:#B7582C;
  --create-form-bg:#FFF5E8;
  --form-input-bg:#FFFBF5;
  --subtle:#efeff5;
  --orange: #E26020;
  --green: #1B6D4D;
  --green-cm-light:#2b5b3f;
  --green-cm:#143829;
  --green-second:#385a41;
  --red: #BB1D3D;
  --gray: #333;
  --grey-bg: #D9D9D9;
  --yellow: #E9972D;
  --text-white: #fff;
  --text-black: #000;
  --text-pink: #dc9ebd;
  --green-text:#1F372A;
  --brown:#933D24;
  --slate:#6b6d7c;
  --red-bg-button:#e72725;

  --gravel:#e3e3e3;
  --rs-filters-btn:#DE9A45;
  --container-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25), 0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
  --cluster-red-shadow:0 0.3rem 1.2rem rgba(187, 29, 61, 1.25), 0 0.7rem 0.7rem rgba(0, 0, 0, 0.72);
  --red-bg-button:#e72725;
  /* Font Size */ 
  --xl-font-size: 72px; 
  --l-font-size: 48px;
  --m-font-size: 30px;
  --s-font-size: 24px;
  --xs-font-size: 18px;
  --xxs-font-size: 16px;
  --text-shadow: 0 2px 40px rgba(0, 0, 0, 0.09);
 

  /* Border Radius */ 
  --radius: 40px;
  --radius-input: 10px;

  /* Spacing eg. Margin and Padding*/
  --xxxl-space: 70px;
  --xxl-space: 60px;
  --xl-space: 50px;
  --l-space: 30px;
  --m-space: 20px;
  --s-space: 10px;

  /* Container Dimensions */
  --container-max-width: 1000px;
  --container-min-width: 340px;
  --container-width: 80%;

  --container-larger-height: 800px;
  --container-height: 600px;
  --container-min-height: 400px;

  /* Toast Notifications */
  --toastify-color-success: #1B6D4D !important;
  --toastify-color-error: #BB1D3D !important;
}

/* Default Font Sizes of Basic Elements */
h1 {
  font-size: var(--xl-font-size);
}

h2 {
  font-size: var(--l-font-size);
}

h3 {
  font-size: var(--s-font-size);
}

label, span, p, input {
  font-size: var(--xs-font-size);
}

p, a {
  font-weight: 460;
}


/* 
@font-face {
  font-family: Phenomena;
  src: url("../../client/public/Phenomena-Regular.woff");
} */
@font-face {
  font-family: 'Montserrat';
  src: url('../../client/public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  /* font-weight: normal; */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../client/public/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05rem;
  text-indent: 0.3rem;

  background: var(--bg-white);
  min-height: 100vh;
}

html, body { 
  height: 100%;
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
a{
    text-decoration: none;
}

button, input, textarea {
  border: none;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: inherit;
  text-indent: inherit;
  font-size: inherit;
}

/* Genral Button Styles */
.btn-general {
  display: inline-block;
  margin-top: 20px;
  padding: 14px 60px;
  border-radius: var(--radius);

  transition: background-color 0.3s ease;

  /* background-color: var(--green); */
  color: var(--text-white);

  font-size: var(--xs-font-size);
  text-decoration: none;
  font-weight: 600;    
  
  cursor: pointer;
}

.btn-general:hover {
  opacity: 0.7;
}

.btn-general:disabled {
  opacity: 0.7;
  cursor:not-allowed;
}

/* Button Colors */
.btn-orange {
  background-color: var(--orange);
  color: var(--bg-white);
}
.btn-green {
  background-color: var(--green);
  color: var(--bg-white);
}

.btn-red {
  background-color: var(--red);
  color: var(--bg-white);
}


/* Container Wrapper */
.container-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: var(--m-space);
	box-sizing: border-box;
}

/* Input Field */
.input{
	background-color: var(--bg-white);
	padding: 14px;
	margin: var(--s-space) 0 var(--m-space) 0;
	width: 100%;
	border: 0.3px solid #ccc;
	border-radius: var(--radius-input);
	
}


.notification {
  margin-top: 10%;
}
