.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy-container h1, 
  .privacy-policy-container h2 {
    color: #2c3e50;
  }
  
  .privacy-policy-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p, 
  .privacy-policy-container ul {
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 10px;
  }
  