.header-community {
    position: relative;
    display: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-white);
    padding: calc(var(--s-space) + 8px) 0;
    position: fixed;
}

.header-community-logo {
    text-align: center;
    margin-left: 2em;
}

.header-community-logo img {
    width: 2em;
    height: auto;
}

.actions-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 30%;
}

.plus-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: var(--green);
    border-radius: 50%;
    padding: 16px;
    margin-right: 10px;
}

.plus-icon {
    color: white !important;
    font-size: 1.5rem;
    padding-right: 0.2em;
}

.language-container {
    display: flex;
    align-items: center;
}

.language-button-community {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.language-button-community .flag-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .header-community {
        justify-content: center;
    }
    .actions-container {
      
        right: 50px;
        
    }
}

@media (min-width: 1024px) {
    .header-community {
        display: none;
    }
}